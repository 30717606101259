.client-reservation-detail {
  padding: 2rem 0;
  background-color: var(--backgroundColorGray);
  &__section-title {
    margin-bottom: 1rem;
    h3 {
      color: var(--textColorDark2);
      padding-bottom: 2px;
    }
    .border-space {
      width: 80px;
      height: 4px;
      background-color: var(--primaryColor);
    }
  }
  &__btn-action {
    padding-top: 1rem;
    button {
      width: 240px;
      height: 64px;
      justify-content: center;
      align-items: center;
      svg {
        margin-left: 0.8rem;
        //font-size: 1.5rem;
      }
    }
    .wait-holder {
      background-color: #9ba2ac;
      width: 380px;
      padding: 1rem;
      border-radius: 8px;
      p {
        margin: 0 0.8rem;
        // text-align: center;
        color: var(--textColorWhite);
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.2);
      }
      @media (max-width: 640px) {
        width: auto;
      }
    }
  }
  .res-detail {
    &__body {
      &--item {
        margin-bottom: 0.8rem;
        p {
          color: var(--textColorLigth);
          padding-bottom: 2px;
        }
        h4 {
          color: var(--textColorDark2);
        }
      }
      &--item-price {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        p {
          color: var(--textColorLigth);
        }
        h3 {
          color: var(--textColorDark2);
          font-family: 'Montserrat-Bold';
          font-size: 1.3rem;
          padding-left: 1rem;
        }
      }
      @media (min-width: 960px) {
        &--grid-1 {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 16px;
        }
      }
    }
  }
  .stay-detail {
    &__flex {
      display: flex;
    }
    &__image {
      width: 40%;
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        user-select: none;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    &__content {
      width: 60%;
      padding-left: 1rem;
      &--item {
        margin-bottom: 0.8rem;
        p {
          color: var(--textColorLigth);
        }
        h4 {
          color: var(--textColorDark2);
        }
      }
      @media (min-width: 960px) {
        &--grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 16px;
        }
      }
    }
    @media (max-width: 960px) {
      &__flex {
        flex-direction: column;
      }
      &__image {
        width: 100%;
      }
      &__content {
        width: 100%;
        padding-top: 1rem;
        padding-left: 0;
      }
    }
  }
  .container {
    .message-wrapper {
      padding-bottom: 10px;
      height: 95%;
      min-height: 640px;
      .str-chat-channel-list,
      .str-chat-channel {
        height: 640px;
        margin: auto;
      }
      .str-chat__container {
        flex-direction: column;
      }
    }
  }
}
