.root {
  padding-top: 2rem;
  background-color: var(--backgroundColor);
  box-shadow: 0 -2px 8px -2px rgba(51, 51, 51, 0.2);
  // border-top: 1px solid rgba(0, 0, 0, 0.12);
  z-index: 99;
}
.container {
  margin-bottom: 2rem;
}
.title {
  font-size: 1.1rem;
  font-weight: var(--fontWeightBold);
  color: var(--textColor);
  margin-bottom: 16px;
}
.linkWrap {
  margin-top: 40px;
}
.link {
  &:hover {
    color: var(--primaryColor);
    text-decoration: underline;
  }
}
.phone {
  display: flex;
  align-items: center;
  img {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 10px;
    user-select: none;
  }
}
.phoneText {
  & > :not(style) + :not(style) {
    margin-top: 5px;
  }
}
.mediaWrap {
  display: flex;
  align-items: center;
  gap: 16px;
  margin-bottom: 16px;
  a,
  img {
    display: block;
  }
}
.media {
  cursor: pointer;
  width: 2.2rem;
}
.legal {
  h6 {
    font-weight: var(--fontWeightSemibold);
    margin-bottom: 2px;
  }
  p {
    font-size: var(--fontSize6);
    font-weight: var(--fontWeightRegular);
  }
}
.copyright {
  background-color: #5a9991;
  padding: 1rem 0;
  .text {
    font-weight: var(--fontWeightMedium);
    font-size: 14px;
    color: var(--textColorWhite);
  }
}
