.name {
  margin-bottom: 1rem;
  min-height: 1.8rem;
}
.action {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 16px;
}
.grid {
  line-height: 1.8;
  @media (min-width: 768px) {
    & {
      margin-left: 20px;
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-auto-rows: 1fr;
      grid-gap: 16px;
      .more {
        grid-column: 1 / span 2;
        margin: 10px 0;
      }
    }
  }
}
.item {
  .title {
    color: var(--textColor);
    text-transform: uppercase;
    font-size: var(--fontSize5);
    font-weight: var(--fontWeightMedium);
  }
  .detail {
    font-size: var(--fontSize4);
    font-weight: var(--fontWeightSemibold);
  }
}
