.search-container {
  display: flex;
  flex-grow: 1;
  // flex-basis: 400px;
  max-width: 400px;
  align-items: center;
  .search-paper {
    height: 42px;
    padding: 0px 16px;
    display: flex;
    flex-grow: 1;
    align-items: center;
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
    .search-icon {
      margin-right: 1rem;
    }
    .search-input {
      font-family: 'Montserrat-Medium';
      flex-grow: 1;
    }
  }
}