@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.houser-detail {
  padding-top: 0.2rem;
  padding-bottom: 2rem;
  &__container {
    padding-top: 2rem;
  }
  &__section-title {
    align-items: center;
    padding-bottom: 1rem;
  }
  &__section-description {
    margin-bottom: 0.5rem;
    p {
      line-height: 1.6;
    }
    .toolbar-group {
      overflow-x: auto;
      justify-content: space-between;
    }
    .description-group {
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: 16px;
      margin: 1.2rem 0;
      &__item {
        display: flex;
        align-items: center;
        min-width: 180px;
        background-color: #f7f7fb;
        padding: 0.6rem 0.6rem;
        border-radius: 8px;
        border: 1px solid rgba(0, 0, 0, 0.1);
        svg {
          margin-right: 0.8rem;
          min-width: 48px;
          width: 48px;
          height: 48px;
        }
        img {
          margin-right: 0.8rem;
          user-select: none;
        }
        &.dog-sm {
          img {
            width: 40px;
            height: 40px;
          }
        }
        &.dog-md{
          img {
            width: 48px;
            height: 48px;
          }
        }
        &.dog-lg{
          img {
            width: 56px;
            height: 56px;
          }
        }
      }
    }
  }
  &__section-video {
    padding: 1.2rem 0;
    .video-container {
      width: 100%;
      height: 400px;
      margin-top: 1rem;
      background-color: #e4e3df;
      &__iframe {
        width: 100%;
        height: 100%;
        border: none;
      }
    }
  }
  &__section-location {
    padding: 1.2rem 0;
    .map-container {
      height: 300px;
      width: 100%;
      margin-top: 1rem;
    }
  }
  &__section-comments {
    padding: 1.2rem 0;
  }
  &__section-user {
    width: 100%;
    text-align: center;
    background-color: #f7f7fb;
    border: 1px solid rgba(0, 0, 0, 0.1);
    border-radius: 8px;
    padding: 1.2rem 0;
    .cuidador-img {
      width: 160px;
      height: 160px;
      border-radius: 50%;
      object-fit: cover;
      text-align: center;
      user-select: none;
    }
  }
  .section-info-call {
    padding-top: 1rem;
    .country-item {
      .country-avatar {
        img {
          width: 48px;
          height: 48px;
        }
      }
      .country-text {
        p {
          margin: 0;
        }
      }
    }
  }
  @media (max-width: 600px) {
    &__container {
      padding-top: 1rem;
    }
    &__grid {
      flex-direction: column-reverse;
    }
    &__section-video {
      .video-container {
        height: 250px;
      }
    }
  }
}
