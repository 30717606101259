.reservation-state {
  background-color: #f1f1f1;
  color: #fff;
  padding: 4px 8px;
  border-radius: 5px;
  span {
    font-size: 15px;
  }
  &.state1 {
    color: rgb(224, 177, 6);
    background-color: rgba(249, 198, 16, 0.2);
  }
  &.state2 {
    color: rgb(241, 71, 38);
    background-color: rgba(241, 72, 38, 0.2);
  }
  &.state3 {
    color: rgb(133, 200, 67);
    background-color: rgba(143, 208, 78, 0.2);
  }
  &.state4 {
    color: rgb(65, 75, 177);
    background-color: rgba(65, 74, 177, 0.2);
  }
  &.state5 {
    color: rgb(93, 93, 93);
    background-color: rgba(184, 184, 185, 0.2);
  }
  &.state6 {
    color: rgb(93, 93, 93);
    background-color: rgba(184, 184, 185, 0.2);
  }
  &.state7 {
    color: rgb(93, 93, 93);
    background-color: rgba(184, 184, 185, 0.2);
  }
}
