.new-pet-container-header {
  background-color: #fff !important;
  .new-pet-stepper {
    padding-left: 0px !important;
    padding-right: 0px !important;
    background-color: #fff !important;
    &__text {
      .MuiStepLabel-label {
        font-family: 'Montserrat-Semibold';
      }
    }
    @media (max-width: 615px) {
      &__text {
        .MuiStepLabel-label {
          display: none;
        }
      }
    }
  }
}
.new-pet-content {
  padding-top: 1rem;
  padding-bottom: 2.5rem;
  .btn-step {
    margin-top: 2rem;
  }
}