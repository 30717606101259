.construction-container {
  height: 100vh;
  .construction-grid {
    height: 100vh;
    .construction-body {
      margin: auto 0px;
      .logo {
        position: absolute;
        top: 40px;
        height: 40px;
      }
      &__center {
        h1 {
          font-size: 3em;
          color: var(--textColorDark);
          margin-bottom: 1rem;
        }
        p {
          margin: 0.5rem 0px;
          line-height: 1.6;
          font-size: 1.05em;
          font-weight: 400;
          color: #555;
        }
        .country-item {
          .country-avatar {
            img {
              width: 48px;
              height: 48px;
            }
          }
          .country-text {
            p {
              margin: 0;
            }
          }
        }
        .button {
          font-family: Montserrat-Semibold;
          max-width: 10em;
          display: block;
          margin: 1.2em auto;
          z-index: 20;
          display: inline-flex;
          svg {
            margin-right: 0.5rem;
          }
        }
      }
      @media (max-width: 960px) {
        &__center {
          margin-top: 6rem;
        }
      }
      @media (max-width: 768px) {
        &__center {
          margin-top: 6rem;
          h1 {
            font-size: 2em;
          }
        }
      }
    }
    .construction-image {
      display: flex;
      align-items: center;
      img {
        width: 100%;
        height: 70%;
        max-width: 600px;
        object-fit: contain;
        @media (max-width: 960px) {
          & {
            display: none;
          }
        }
      }
    }
  }
}