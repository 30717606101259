.message-wrapper {
  padding: 1.5rem;
  height: 95%;
  min-height: 400px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  box-shadow: none !important;
  .str-chat-channel-list,
  .str-chat-channel {
    min-height: 92%;
    margin: auto;
  }
  .str-chat__container {
    flex-direction: column;
  }
  .section-title {
    padding-bottom: 0.5rem;
    .border-space {
      width: 80px;
      height: 4px;
      background-color: var(--primaryColor);
      margin-bottom: 0.5rem;
    }
  }
}