.dh-badge-level {
  display: flex;
  align-items: center;
  box-sizing: border-box;
  color: var(--textColorDark2);
  font-family: 'Montserrat-Semibold';
  font-size: 14px;
  // text-align: center;
  svg {
    margin-right: 0.1rem;
  }
  &.junior {
    color: #80cdc5;
  }
  &.senior {
    color: #e47174;
  }
  &.super {
    color: #f9a64a;
  }
}