.root {
  display: block;
  min-height: calc(100vh - 128px);
  padding-top: 32px;
  padding-bottom: 32px;
  &.disableGutters {
    min-height: calc(100vh - 65px);
    padding-top: 0;
    padding-bottom: 0;
    @media only screen and (max-width: 600px) {
      min-height: calc(100vh - 57px);
    }
  }
  @media screen and (max-width: 1024px) {
    min-height: calc(100vh - 112px);
    padding-top: 24px;
    padding-bottom: 24px;
  }
  @media only screen and (max-width: 600px) {
    min-height: calc(100vh - 105px);
    padding-top: 16px;
    padding-bottom: 16px;
  }
}
