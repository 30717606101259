.header {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px;
  h4 {
    vertical-align: middle;
    line-height: 1.5;
    color: var(--textColorDark2);
  }
}
.actions {
  &:global(.MuiDialogActions-root) {
    padding: 10px;
  }
}
.close {
  position: absolute;
  top: 10px;
  right: 10px;
  left: auto;
  z-index: 10;
  color: var(--textColorDark2);
}
