.dh-home {
  &__title {
    font-size: 2.2rem;
    font-family: 'Montserrat-Bold';
    text-align: start;
    color: var(--textColor);
    padding-bottom: 1.5rem;
  }
  .home-divider {
    width: 100%;
    height: 100px;
    background-image: url('../../assets/images/divider.png');
    background-repeat: repeat;
  }
  .dh-dashed-line {
    margin: 0 7rem;
    display:flex;
    justify-content: space-between;
    position: relative;
    top: 177px;
    z-index: 60;
    .line {
      width: 30px;
      height: 8px;
      background: var(--primaryColor);
      border-radius: 16px;
    }
  }
  @media only screen and (max-width: 960px) {  
    .dh-dashed-line {
      visibility: hidden;
    }
  }
  /* ---------- Start Main Content ---------- */
  &__main {
    width: 100%;
    height: 91vh;
    background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url('../../assets/images/banner_01@0.4.jpg') no-repeat center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    display: flex;
    align-items: center;
    .heroContent {
      text-align: left;
      .dh-img-white {
        width: 48%;
        height: 48%;
      }
      .main-text {
        color: var(--textColorWhite);
        font-family: 'Montserrat-Semibold';
        font-size: 2.6rem;
        padding-bottom: 0.5rem;
      }
      .select-country {
        min-width: 14rem;
      }
      .btn-default {
        margin-bottom: 1rem;
      }
    }
    @media only screen and (min-width: 576px) {
      & {
        display: flex;
        align-items: center;
        background: linear-gradient(rgba(0, 0, 0, 0.5),rgba(0, 0, 0, 0.5)), url('../../assets/images/banner_01.jpg') no-repeat center;
        -webkit-background-size: cover;
        -moz-background-size: cover;
        -o-background-size: cover;
        background-size: cover;
      }
    }
  }
  /* ---------- End Main Content ----------- */
  @media only screen and (max-width: 576px) {  
    .makeStyles-heroContent-2 {
      padding: 120px 0px 48px !important;
    }
  }
  /* ------ Start Why use Dog Houser Content ------ */
  .why-use {
    margin-bottom: 3rem;
    margin-top: 3rem;
    .panel {
      display: grid;
      grid: 300px auto min-content / 100%;
      border-radius: 10px;
      overflow: hidden;
      background: var(--backgroundColor);
      box-shadow: 0 3px 15px rgba(51, 51, 51, 0.2);
      min-height: 100px;
      max-width: 480px;
      margin: auto;
      transition: 0.2s ease;
      &:hover {
        transform: scale(1.05, 1.05);
        box-shadow: 0 3px 15px rgba(51, 51, 51, 0.4);
      }
      &__image {
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
          object-fit: cover;
          transition: 0.2s ease;
        }
      }
      &__info {
        padding: 0.5rem 1rem 1rem;
        h2 {
          font-size: 16px;
          margin-bottom: 8px;
        }
        p {
          font-size: 14px;
          color: var(--textColor);
          margin: 8px 0;
        }
      }
    }
  }
  /* ------- End Why use Dog Houser Content ------- */
  /* --------- Start How Work Dog Houser ----------- */
  .how-work {
    padding-bottom: 16px;
    background: var(--backgroundColor);
    &__container {
      padding-top: 24px !important;
      .dog-card {
        .perro-about {
          &-1 {
            width: 150px;
            height: 140px;
          }
          &-2 {
            width: 128px;
            height: 95px;
            margin: 22px;
          }
          &-3 {
            width: 150px;
            height: 140px;
          }
          &-4 {
            width: 150px;
            height: 130px;
            margin: 6px;
          }
        }
        &__number {
          width: 48px;
          height: 48px;
          align-content: center;
          background-color: var(--white);
          border: 5px solid var(--primaryColor);
          border-radius: 16px;
          position: relative;
          z-index: 80;
          p {
            margin: 0;
            padding-top: 4px;
            font-family: 'Montserrat-Bold';
            font-size: 34px;
            font-weight: 700;
            color: var(--primaryColor);;
          }
        }
        &__title {
          font-family: 'Montserrat-Bold';
          font-size: 18px;
          color: var(--textColor) !important;
        }
        &__body {
          color: var(--textColor);
          font-family: 'Montserrat-Medium';
          font-size: 14px;
          line-height: 26px;
          margin: 0 18px;
        }
      }
    }
  }
  /* ---------- End How Work Dog Houser ---------- */
  /* ---------- Start Media Trust  ---------- */
  .media-trust {
    margin: 3rem 0;
  }
  /* ------ Start Cuidadores Rsating ------ */
  .cuidadores-rating {
    background-color: rgb(124, 124, 124);
    padding: 2rem 1rem;
    .rating-card {
      border-radius: 0.4rem;
      width: 304px;
      min-height: 370px;
      padding: 1.5rem 1rem;
      background-color: #f9f9f9;
      box-shadow: 0 12px 32px 4px rgba(black, .2);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      transition: .2s;
      &__patitas {
        .fav {
          width: 26px;
          height: 22px;
          margin: 0 0.2rem;
          filter: invert(59%) sepia(86%) saturate(683%) hue-rotate(1deg) brightness(104%) contrast(105%);
        }
      }
      &__title {
        font-family: 'Montserrat-Bold';
      }
      &__text {
        margin: 0.6rem 0.8rem;
        color: var(--textColor);
        text-align: justify;
        line-height: 21px;
      }
      &__user {
        display: flex;
        flex-direction: column;
        align-items: center;
        &__img {
          display: flex;
          align-items: center;
          width: 100px;
          height: 100px;
        }
        &__name {
          font-size: 1.2rem;
          margin: 1rem 0 0;
        }
      }
      &:hover{
        transform: scale(1.05);
      }
    }
  }
  /* ------ End Housers ------ */
  /* ------ Start Alliest ------ */
  .alliest {
    margin: 3rem 0;
  }
}
