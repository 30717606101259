.client-reservation-list {
  padding: 2rem 0;
  background-color: var(--backgroundColorGray);
  &__title {
    padding-bottom: 1rem;
  }
  &__toolbar {
    overflow-x: auto;
    padding: 0.5rem 0.3rem;
    margin-left: -0.3rem;
  }
  &__actions {
    display: flex;
    button {
      background-color: var(--white) !important;
      &:nth-last-child(1) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0.3rem;
      }
      &:nth-last-child(2),
      &:nth-last-child(3) {
        border-radius: 0;
        margin: 0 0.3rem;
      }
      &:nth-last-child(4) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0.3rem;
      }
      &.active {
        background-color: var(--primaryColorDark) !important;
        color: var(--textColorWhite);
      }
    }
  }
  &__content {
    margin-top: 1rem;
    margin-bottom: 1.5rem;
    .reservation-card {
      transition: 0.2s ease;
      cursor: pointer;
      &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      &__user-name {
        padding: 0.25rem 0;
      }
      &__item {
        display: flex;
        align-items: center;
        padding: 5px 0;
        color: var(--textColor);
        svg {
          font-size: 1.4rem;
        }
        span {
          margin-left: 4px;
          font-size: 15px;
        }
      }
      &:hover {
        box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
        transform: scale(1.02, 1.02);
      }
    }
  }
}
