.dh-admin-places {
	&__header {
		display: grid;
    grid-template-columns: .5fr 1.5fr .5fr;
		justify-items: center;
		&__new-place {
			display: flex;
			align-items: center;
		}
	}
}
