.message-window {
  .container {
    margin: 20px auto;
    .summary-grid {
      padding: 15px;
      .summery-title {
        text-align: center;
      }
      .summary-section-title {
        margin-top: 20px;
      }
      .summary-text {
        line-height: 30px;
      }
      .invoice-text {
        line-height: 30px;
        .invoice-row {
          display: flex;
          justify-content: space-between;
        }
        .row-description {
          line-height: 20px;
          font-weight: lighter;
          font-size: 12px;
        }
      }
      .subtotal-text {
        margin-top: 20px;
        text-align: right;
      }
    }
    .message-wrapper {
      padding: 10px;
      height: 95%;
      min-height: 400px;
      .str-chat-channel-list,
      .str-chat-channel {
        height: 96%;
        margin: auto;
      }
      .str-chat__container {
        flex-direction: column;
      }
    }
  }
}