.houser-detail {
  &__container-error {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 2.5rem;
    padding-bottom: 1.5rem;
    &--title {
      padding-bottom: 2.5rem;
      h1 {
        text-align: center;
      }
    }
    &--image {
      margin-bottom: 2rem;
      text-align: center;
      img {
        width: 75%;
        user-select: none;
      }
    }
  }
}
