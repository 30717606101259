.dh-profile-summary {
  display: flex;
	align-items: center;
	box-sizing: border-box;
  cursor: pointer;
  width: 100%;
	margin: 0;
	padding: 0.5rem 1rem;
	&__ico {
    width: 54px;
		height: 54px;
		margin-right: 0.6rem;
    .profile-image {
      width: 100%;
      height: 100%;
      object-fit: cover;
      text-align: center;
    }
  }
  &__name {
		text-decoration: none;
    p {
			color: var(--textColor);
			font-size: 0.8rem;
			margin: 0.3rem 0;
			&.name {
				font-family: 'Montserrat-Semibold';
				font-size: 1.1rem;
			}
    }
  }
}