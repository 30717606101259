.formGroup {
  & > :not(style) + :not(style) {
    border-top: 1px solid rgba(176, 190, 197, 0.459);
  }
}
.formLabel {
  &:global(.MuiFormControlLabel-root) {
    padding: 10px 0;
    margin: 0;
  }
}
.item {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
}
.title {
  font-weight: 600;
  margin-bottom: 5px;
}
.content {
  display: flex;
  flex-direction: column;
  & > :not(style) + :not(style) {
    margin-top: 3px;
  }
}
.hint {
  font-style: italic;
  color: var(--textColorLigth);
}
