.register-step-2 {
  text-align: center;
  .image-progress {
    width: 260px;
    height: 260px;
    position: relative;
    img {
      opacity: 0.5;
    }
    &__circle {
      position: absolute;
      top: 46%;
      left: 50%;
      margin-left: -24px;
      color: var(--primaryColorDark);
    }
  }
  .profile-img-container {
    margin: 1.5rem auto;
    width: 260px;
    height: 260px;
    .profile-img {
      width: 100%;
      height: 100%;
      cursor: pointer;
      text-align: center;
      object-fit: cover;
      border-radius: .5rem;
    }
    .drop-zone {
      width: 100%;
      height: 100%;
      cursor: pointer;
      border-radius: .5rem;
      border: 2px dashed #ccc;
      background-color: #e8ebed;
      display: table;
      &__content {
        display: table-cell;
        vertical-align: middle;
        svg {
          width: 80px;
          height: 80px;
        }
      }
    }
  }
  p {
    margin: 1.5rem 5rem;
  }
  .form-container {
    margin-bottom: 3rem;
    #upload-photo {
      opacity: 0;
      display: none;
      z-index: -1;
    }
    .btn-primary {
      width: auto;
      margin: .8rem 1rem;
      svg {
        margin-right: .3rem;
        display: inline-block;
        vertical-align: middle;
      }
    }
  }
  @media only screen and (max-width: 601px) {  
    p {
      margin: 1rem 0;
    }
  }
}