.menu-icon {
  float: left;
  display: block;
  width: 45px;
  height: 48px;
  background: var(--accentColor);
  border-radius: var(--borderRadius);
  .text-lines {
    position: relative;
    display: inline-block;
    margin: auto 0;
  }
  .text-lines span {
    margin: 0 auto;
    position: relative;
    top: 12px;
  }
  .text-lines span:before,
  .text-lines span:after {
    position: absolute;
    content: '';
  }
  .text-lines span,
  .text-lines span:before,
  .text-lines span:after {
    width: 30px;
    height: 6px;
    background-color: var(--white);
    border-radius: var(--borderRadius);
    display: block;
  }
  .text-lines span:before {
    margin-top: -12px;
  }
  .text-lines span:after {
    margin-top: 12px;
  }
  /* Effect */
  .effect span {
    -webkit-transition-duration: 0s;
    transition-duration: 0s;
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .effect:hover span {
    background-color: rgba(0, 0, 0, 0);
    -webkit-transition-delay: 0.2s;
    transition-delay: 0.2s;
  }
  .effect span:before {
    -webkit-transition-property: margin, -webkit-transform;
    transition-property: margin, transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0s;
    transition-delay: 0.2s, 0s;
  }
  .effect:hover span:before {
    margin-top: 0;
    -webkit-transform: rotate(45deg);
    transform: rotate(45deg);
    -webkit-transition-delay: 0s, 0.2s;
    transition-delay: 0s, 0.2s;
  }
  .effect span:after {
    -webkit-transition-property: margin, -webkit-transform;
    transition-property: margin, transform;
    -webkit-transition-duration: 0.2s;
    transition-duration: 0.2s;
    -webkit-transition-delay: 0.2s, 0s;
    transition-delay: 0.2s, 0s;
  }
  .effect:hover span:after {
    margin-top: 0;
    -webkit-transform: rotate(-45deg);
    transform: rotate(-45deg);
    -webkit-transition-delay: 0s, 0.2s;
    transition-delay: 0s, 0.2s;
  }
}
