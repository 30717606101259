@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';

.dh-admin-reservations {
  &__section-header {
    padding-bottom: 0.4rem;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    &--actions {
      display: flex;
      button {
        background-color: var(--white) !important;
        &:nth-last-child(1) {
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border-top-left-radius: 0;
          border-bottom-left-radius: 0;
          margin-left: 0.3rem;
        }
        &:nth-last-child(2) {
          border-top-right-radius: 0;
          border-bottom-right-radius: 0;
          border-top-left-radius: 8px;
          border-bottom-left-radius: 8px;
          margin-right: 0.3rem;
        }
        &.active {
          background-color: var(--primaryColorDark) !important;
          color: var(--textColorWhite);
        }
      }
    }
  }
}

.fc-button-primary {
  background-color: var(--primaryColor);
  border: solid 0;
  &:hover,
  &:focus,
  &:active,
  &:not(:disabled):active {
    background-color: var(--primaryColorDark);
    border-color: var(--primaryColorDark);
  }
}

.fc-view-container {
  background: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
}

.fc-head {
  background-color: #fafafa;
  .fc-day-header {
    font-family: 'Montserrat-Semibold';
    padding: 4px 0;
  }
}

.fc-body {
  .fc-day-grid-event {
    padding: 2px 6px;
  }
}
