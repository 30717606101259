.cuidador-publication-info {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  background-color: var(--backgroundColorGray);
  min-height: 52.5vh;
  &__title {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  &__sub-title {
    padding-top: 1rem;
    padding-bottom: 0.2rem;
  }
  p {
    margin-bottom: 1rem;
  }
  .video-container {
    margin-top: 1.5rem;
  }
  iframe {
    width: 60%;
    height: 400px;
    border: none;
  }
  .btn-primary {
    margin-top: 1.5rem;
  }
  @media only screen and (max-width: 900px) {
		iframe {
      width: 100%;
    }
  }
}