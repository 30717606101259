.step-cuidador-location {
  &__section-title {
    padding-top: 1rem;
    padding-bottom: 0.5rem;
    h3 {
      color: var(--accentColor);
      text-shadow: 1px 1px 3px rgba(249, 167, 74, 0.2);
    }
  }
  &__map-container {
    width: 100;
    height: 50vh;
    background-color: #e4e3df;
  }
}
