html,
body {
  padding: 0;
  margin: 0;
  // font-family: 'Montserrat-Medium';
  font-family: var(--fontFamily);
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
html {
  -ms-text-size-adjust: 100%;
  -webkit-text-size-adjust: 100%;
  font-size: 100%;
  text-size-adjust: 100%;
}
body {
  background-color: var(--backgroundColor);
  color: var(--textColor);
  font-size: var(--fontSizeBase);
  font-weight: var(--fontWeightMedium);
  &::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: var(--white);
  }
  &::-webkit-scrollbar {
    width: 8px;
    background-color: var(--white);
  }
  &::-webkit-scrollbar-thumb {
    border-radius: 10px;
    border: 1px solid var(--white);
    background-color: var(--gray);
  }
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
img {
  max-width: 100%;
  // vertical-align: top;
}
a {
  color: inherit;
  text-decoration: none;
}
h1,
h2,
strong {
  font-weight: var(--fontWeightBold);
}
h3,
h4 {
  font-weight: var(--fontWeightSemibold);
}
em {
  font-style: italic;
}
.dh-typeset h1,
h1 {
  font-size: var(--fontSize1);
}
.dh-typeset h2,
h2 {
  font-size: var(--fontSize2);
}
.dh-typeset h3,
h3 {
  font-size: var(--fontSize3);
}
.dh-typeset h4,
h4 {
  font-size: var(--fontSize4);
}
h5 {
  font-size: var(--fontSize5);
}
.dh-button,
h6,
p {
  font-size: var(--fontSizeBase);
}
