.dh-petfriendly {
  &__main-image {
    width: 100%;
    height: 92vh;
    overflow: hidden;
    display: flex;
    align-items: center;
    background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://galery-doghouser.s3-us-west-2.amazonaws.com/assets/pet-friendly/banner-petfriendly.jpeg') no-repeat center center;
    background-size: cover;
    &__container {
      width: 40%;
    }
    h1 {
      color: var(--textColorWhite);
      font-family: 'Montserrat-Semibold';
      font-size: 34px;
      letter-spacing: 1px;
      padding-bottom: 1.2rem;
    }
    h3 {
      color: var(--textColorWhite);
      font-family: 'Montserrat-Italic';
      font-size: 18px;
      letter-spacing: 1px;
    }
  }

  &__title-section {
    padding-bottom: 1rem;
    h2 {
      color: var(--textColor);
      margin: 0;
    }
  }

  @media (max-width: 768px) {
    &__main-image {
      width: 100%;
      height: 90vh;
      overflow: hidden;
      display: flex;
      align-items: center;
      background: linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ), url('https://galery-doghouser.s3-us-west-2.amazonaws.com/assets/pet-friendly/banner-petfriendly.jpeg') no-repeat center center;
      background-size: cover;
      &__container {
        width: 100%;
        text-align: center;
        align-items: center;
      }
    }
  }
  &__map-container{
    margin-top: 1rem;
    width: 100%;
    height: 500px;
  }
  .toggle-buttons{
    padding: 0.5rem 0;
  }
}


.dh-card-petfriendly-map {
  width: 272px;
  padding: 0;
  background-color: var(--backgroundColor) !important;
  border-radius: 1rem;
  &__cover-image {
    width: 100%;
    height: 144px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      border-top-left-radius: 1rem;
      border-top-right-radius: 1rem;
    }
  }
  &__detail {
    padding: 1rem;
    h3 {
      margin: 0;
      padding-bottom: 4px;
      color: var(--primaryColorDark2);
      font-family: 'Montserrat-Semibold';
      font-size: 1rem;
    }
    p {
      font-size: .8rem;
      color: var(--accentColor);
    }
    span {
      color: var(--textColorDark2);
      font-family: 'Montserrat-Semibold';
      font-size: .9rem;
    }
    &__contain{
      display: flex;
      justify-content: space-between;
      span{
        font-size: .7rem;
      }
    }
    .dh-flex
    .place-image-container {
      width: 80px;
      height: 40px;
      margin-right: 0.5rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        border-radius: 50%;
      }
    }
  }
}

