.houser-list {
  &__filter-container {
    width: 100%;
    min-height: 64px !important;
    background-color: var(--white);
    // border-bottom: 1px solid var(--borderColor);
    border-top: 1px solid rgba(0, 0, 0, 0.1);
    position: fixed !important;
    overflow-x: auto;
    z-index: 998;
    display: none !important;
    box-shadow: 0 6px 8px -2px rgba(51, 51, 51, 0.2);
  }
  &__appbar {
    position: fixed;
    z-index: 998;
    background-color: var(--white);
    box-shadow: 0 6px 8px -2px rgba(51, 51, 51, 0.2);
    width: 100%;
    padding-top: 0rem;
  }
  &__main-content {
    padding-top: 3rem;
  }
  @media (min-width: 960px) {
    &__filter-container {
      display: flex !important;
    }
    &__appbar {
      padding-top: 4rem;
      // display: none;
    }
    &__main-content {
      padding-top: 4rem;
    }
  }
  &__tab-filters {
    padding: 1rem 1rem 3rem 1rem;
  }
  &__housers {
    // overflow: auto;
    padding: 0;
    background-color: var(--backgroundColorGray);
    box-shadow: 8px -10px 15px -4px rgba(0, 0, 0, 0.3) !important;
    z-index: 99;
    .search-container {
      width: 100%;
      max-width: 100%;
    }
    .MuiGrid-item {
      padding-left: 1rem;
      padding-right: 1rem;
    }
    @media (max-width: 599px) {
      & {
        padding: 0 1.5rem;
      }
      .MuiGrid-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
    @media (min-width: 960px) {
      & {
        padding: 0 1.5rem;
      }
      .MuiGrid-item {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  &__map-container {
    position: fixed;
    overflow: hidden;
    height: 100%;
    top: auto;
    bottom: 0;
    right: 0;
    width: 50%;
    z-index: 1;
    @media only screen and (max-width: 954px) {
      & {
        display: none;
      }
    }
  }
  &__map-layout-all {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
  }
  &__popover-filter {
    .MuiPaper-root {
      margin-top: 0.5rem;
      border-radius: 12px;
      border-width: 0.5px;
      border-style: solid;
      border-color: rgba(118, 118, 118, 0.48);
      min-width: 272px;
    }
    .popover-title {
      border-bottom: 1px solid rgb(235, 235, 235);
      padding: 1rem 1.2rem 0.8rem 1.2rem;
      h2 {
        font-family: 'Montserrat-Medium';
        font-size: 1.2rem;
        margin: 0;
      }
    }
    .popover-formcontrol {
      padding: 0.9rem 1.2rem;
      // width: 100%;
      .MuiFormControlLabel-root {
        margin-right: 0;
        .MuiTypography-body1 {
          font-family: 'Montserrat-Medium';
          color: var(--textColor);
        }
      }
    }
    .popover-actions {
      display: flex;
      justify-content: space-between;
      padding: 1rem 1rem 1rem 0.5rem;
      border-top: 1px solid rgb(235, 235, 235);
      .btn-default {
        visibility: hidden;
        padding: 10px;
      }
    }
  }
}

.dh-card-houser-map {
  width: 272px;
  padding: 0;
  background-color: var(--backgroundColor) !important;
  border-radius: 8px;
  &__cover-image {
    width: 100%;
    height: 144px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      border-top-left-radius: 8px;
      border-top-right-radius: 8px;
    }
  }
  &__detail {
    padding: 1rem;
    h3 {
      margin: 0;
      padding-bottom: 4px;
      color: var(--primaryColorDark2);
      font-family: 'Montserrat-Semibold';
      font-size: 1rem;
    }
    p {
      padding-bottom: 8px;
      font-size: 1rem;
    }
    span {
      color: var(--textColorDark2);
      font-family: 'Montserrat-Semibold';
      font-size: 1rem;
    }
    .user-image-container {
      width: 40px;
      height: 40px;
      margin-right: 0.5rem;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        user-select: none;
        border-radius: 50%;
      }
    }
  }
}

.MuiTooltip-tooltip {
  width: 272px !important;
  max-width: 272px !important;
  padding: 0 !important;
  border-radius: 1rem !important;
  border: none !important;
  box-shadow: rgba(0, 0, 0, 0.28) 0px 8px 28px !important;
}

.dh-card-houser-movil {
  position: absolute;
  bottom: 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  z-index: 999999;
  transition: 0.2s ease;
  &__card-desing {
    display: flex;
    margin: 0.5rem;
    background-color: var(--backgroundColor);
    border-radius: 8px;
  }
  &__cover-image {
    width: 40%;
    height: 160px;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      user-select: none;
      border-bottom-left-radius: 8px;
      border-top-left-radius: 8px;
    }
  }
  &__content {
    width: 60%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 1rem;
    h3 {
      margin: 0;
      padding-bottom: 2px;
      font-family: 'Montserrat-Bold';
      color: var(--textColorDark2);
    }
    &__title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    &__location {
      display: flex;
      align-items: center;
      padding-bottom: 0.5rem;
      svg {
        color: var(--primaryColorDark);
        width: 1.2rem;
        height: 1.2rem;
      }
      span {
        color: var(--primaryColorDark);
        font-family: 'Montserrat-Medium';
        font-size: 0.9rem;
        line-height: 1.4;
      }
    }
    p {
      padding-bottom: 0.3rem;
    }
  }
}
