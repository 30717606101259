.cuidador-servicios {
	padding-top: 1.5rem;
  padding-bottom: 2rem;
  background-color: var(--backgroundColorGray);
	min-height: 52.5vh;
	&__title {
		padding-top: 0.5rem;
    padding-bottom: 0.5rem;
	}
	&__sub-title {
		padding-top: 1rem;
	}
	&__servicos-list,
	&__medicines-list,
	&__zones-list {
		margin: 1rem 0 1.5rem 0;
		label {
			width: 80px;
			text-align: center;
			margin: 0 1.5rem;
		}
		svg {
			width: 64px;
			height: 64px;
		}
	}
	&__size-list {
		label {
			max-width: 140px;
			display: flex;
   		justify-content: flex-end;
   		align-items: center;
		}
		.dog-small {
			svg {
				width: 64px;
				height: 64px;
			}
		}
		.dog-medium {
			svg {
				width: 80px;
				height: 80px;
			}
		}
		.dog-big {
			svg {
				width: 96px;
				height: 96px;
			}
		}
	}
	.btn-primary {
		margin-top: 1.5rem;
	}
	@media only screen and (max-width: 625px) {
		&__servicos-list,
		&__medicines-list,
		&__zones-list,
		&__size-list {
			div {
				justify-content: center;
			}
			label {
				margin: 0 1rem;
			}
		}
  }
}
