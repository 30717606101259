.dh-cuidador-info-image{
  background: 
  linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),
  url('../../assets/images/ft-confianza.png') no-repeat center center;
  background-size: cover;
  background-position: center center;
  background-repeat: no-repeat;
  margin: 0;
  height: 400px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  &__container{
    width: 50%;
    h1 {
      color: var(--textColorWhite);
      font-family: 'Montserrat-Semibold';
      font-size: 34px;
      letter-spacing: 1px;
      margin-top: 0;
    }
    h3 {
      color: var(--textColorWhite);
      font-family: 'Montserrat-Italic';
      font-size: 18px;
      letter-spacing: 1px;
    }
  }
}
.dh-cuidador-info-content{
  text-align: justify;
  &__image{
    background-image: url('../../assets/images/seleccion-1024x1024.png');
    background-position: center center;
    border-radius: 4px;
    background-size: cover;
    height: 500px;
  }
  blockquote{
    font-size: 2em;
    h3 {
      color: var(--textColor);
    }
  }
  h3 {
    color: var(--primaryColor);
    font-size: 1.4rem;
    padding-top: 0.7rem;
    padding-bottom: 0.7rem;
    
  }
  .divider-line {
    margin-bottom: 1rem;
  }
  ul {
    li {
      list-style: decimal;
      margin-bottom: 1em;
      p {
        font-weight: bolder;
        line-height: 1.5em;
      }
    }
  }
  ul:last-of-type {
    margin-bottom: 2em;
  }
}