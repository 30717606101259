.admin-grid-pets {
  &__toolbar {
    overflow-x: auto;
    justify-content: space-between;
  }
  &__header {
    align-items: center;
    font-size: 19px;
    color: var(--textColor);
    font-family: 'Montserrat-Semibold';
    line-height: 20px;
    letter-spacing: 0.1px;
    padding-bottom: 1.2rem;
  }
  &__body {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-gap: 16px;
    &--item {
      display: flex;
      align-items: center;
      background-color: #f2f2f4;
      border-top-left-radius: 36px;
      border-bottom-left-radius: 36px;
      border-top-right-radius: 8px;
      border-bottom-right-radius: 8px;
      border: 1px solid rgba(0, 0, 0, 0.1);
      padding: 0.3rem 0.3rem;
      .MuiAvatar-root {
        width: 64px;
        height: 64px;
        color: var(--colorPrimary);
        margin-right: 0.8rem;
        background-color: #d4d4d4;
        transition: 0.2s ease;
        &:hover {
          transform: scale(1.05, 1.05);
        }
      }
    }
  }
}