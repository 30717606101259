.root {
  & > :not(style) + :not(style) {
    margin: 16px 0px 0px;
  }
}

.label {
  display: block;
  font-weight: var(--fontWeightSemibold);
  margin-bottom: 5px;
}

.label2 {
  display: block;
  font-weight: var(--fontWeightSemibold);
  margin-bottom: 5px;
}

.typeWrap {
  &:global(.MuiFormGroup-root) {
    flex-direction: row;
    justify-content: space-around;
  }
}
.sizeWrap {
  &:global(.MuiFormGroup-root) {
    flex-direction: row;
    align-items: flex-end;
    justify-content: space-around;
    margin-top: -8px;
  }
  .small {
    svg {
      width: 40px;
      height: 40px;
    }
  }
  .medium {
    svg {
      width: 56px;
      height: 56px;
    }
  }
  .big {
    svg {
      width: 64px;
      height: 64px;
    }
  }
}
