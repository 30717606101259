.root {
  width: 144px;
  height: 144px;
  margin: auto;
  padding: 8px;
  border: 1px dashed rgba(145, 158, 171, 0.32);
  &.circular {
    border-radius: var(--borderRadiusRounded);
    .button {
      border-radius: var(--borderRadiusRounded);
    }
  }
  &.rounded {
    border-radius: var(--borderRadiusMedium);
    .button {
      border-radius: var(--borderRadiusMedium);
    }
  }
  &.square {
    border-radius: 0px;
    .button {
      border-radius: 0px;
    }
  }
}
.button {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  outline: none;
  overflow: hidden;
  z-index: 0;
  cursor: pointer;
  & > * {
    width: 100%;
    height: 100%;
  }
  &:hover .placeholder {
    opacity: 0.72;
  }
}
.imgWrap {
  width: 100%;
  height: 100%;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.placeholder {
  position: absolute;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  color: rgb(99, 115, 129);
  background-color: rgb(244, 246, 248);
  &.full {
    opacity: 0;
    color: rgb(255, 255, 255);
    background-color: rgb(22, 28, 36);
  }
  svg {
    margin-bottom: 8px;
  }
}
