.card-service-panel {
  display: grid;
  grid: 180px auto min-content / 100%;
  border-radius: 16px;
  overflow: hidden;
  background: var(--backgroundColor);
  border: 2px solid var(--borderColor);
  box-shadow: 0 3px 15px rgba(51, 51, 51, 0.3);
  min-height: 270px;
  margin: auto;
  transition: 0.2s ease;
  &:hover {
    transform: scale(1.04, 1.04);
    box-shadow: 0 4px 16px rgba(51, 51, 51, 0.5);
  }
  &__image {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.2s ease;
    }
  }
  &__info {
    padding: 0.2rem 0.9rem 0.9rem 0.9rem;
    .flex-text{
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex-wrap: wrap;
      span{
        flex: 5%;
        display: inline-block;
        padding: 10px;
        font-size: 12px;
        font-weight: bold;
        color: var(--textColor);
        margin: 0;
      }
      h2 {
        flex: 64%;
        font-size: 16px;
        margin-bottom: 8px;
        color: var(--textColorDark)
      }
    }
    p {
      font-size: 14px;
      color: var(--textColor);
      margin: 0;
    }
  }
}
