.dh-register {
  .dh-city-selector {
    .MuiInputBase-formControl {
      height: 55px;
    }
  }
  .btn-register {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
}

.btn-crear {
  background-color: #80cdc5 !important;
  border-radius: 20px !important;
  padding: 10px 24px !important;
  text-transform: none !important;
  span {
    font-size: 1.2rem;
  }
}
