
.admin-main {
  &__content {
    .admin-title {
      margin-right: 1rem;
    }
    .card-admin-user {
      &__header {
        padding: 16px 24px;
        align-items: center;
        font-size: 16px;
        font-family: Montserrat-Semibold;
        font-weight: 500;
        line-height: 20px;
        letter-spacing: -0.05px;
      }
      &__divider {
        border: none;
        height: 2px;
        margin: 0;
        flex-shrink: 0;
        background-color: #eeeeee;
      }
      &__content {
        padding: 0;
        text-overflow: ellipsis;
        overflow: hidden;
        .dh-table {
          width: 100%;
          display: table;
          border-spacing: 0;
          border-collapse: collapse;
          tr {
            color: inherit;
            display: table-row;
            outline: none;
            vertical-align: middle;
            td {
              padding: 14px 40px 14px 16px;
              font-weight: 400;
              font-size: 14px;
              text-align: left;
              line-height: 21px;
              border-bottom: 1px solid #eeeeee;
              letter-spacing: -0.05px;
              vertical-align: inherit;
            }
          }
          .dh-selected {
            background-color: #F4F6F8;
          }
        }
      }
      &.second {
        margin-top: 2rem;
      }
    }
    .dh-pet-resume {
      display: flex;
      align-items: center;
      &_img {
        width: 56px;
        height: 56px;
        margin-right: 1rem;
      }
    }
  }
}
