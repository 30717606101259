.card-pet-friendly {
  border-radius: 8px;
  padding: 0.5rem;
  &__elevation {
    display: grid;
    grid: 260px auto min-content / 100%;
    border-radius: 8px;
    overflow: hidden;
    background: var(--backgroundColor);
    box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
    margin: auto;
    transition: 0.2s ease;
  }
  &__image {
    display: flex;
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: 0.2s ease;
    }
    &__not-fount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 4rem;
        height: 4rem;
      }
      p {
        padding-top: 0.5rem;
        font-size: 1.2rem;
        font-family: 'Montserrat-Bold';
        text-align: center;
        color: var(--textColorDark2);
      }
    }
  }
  &__name {
    padding-top: 0.8rem;
    h3 {
      margin: 0;
    }
  }
  &:hover {
    background-color: rgba(0,0,0,.08);
  }
}
