.dh-payment-card{
    position: relative;
    max-height: 260px;
    background-size: cover;
    .payment-bank-name,
    .payment-bank-card-number,
    .payment-bank-cci{
        h3 {
            color: var(--primaryColor);
            font-size: 1.1rem;
            margin: 0;
        }
        p {
            color: var(--textColor);
            font-size: 1rem;
            padding: 0.5rem 0;
        }
    }
    .payment-bank-name {
        display: flex;
        justify-content: space-between;
        &__icons {
            display: flex;
            justify-content: space-between;
            flex-direction: column;
            svg {
                width: 18px;
                height: 18px;
                border-radius: 20%;
                padding: 0.4rem;
                transition: 0.2s ease;
                &:hover {
                    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
                    transform: scale(1.1);
                }
            }
        }
        &__editIcon {
            cursor: pointer;
            transition: opacity 0.8s ease;
            opacity: 0;
            svg {
                fill: var(--white);
                background-color: var(--primaryColor);
            }
        }
        &__trashIcon {
            position: relative;
            top: 170%;
            left: 0%;
            transition: opacity 0.8s ease;
            opacity: 0;
            cursor: pointer;
            svg {
                background-color: rgba(0, 0, 0, 0.7);
            }
        }
    }
    &:hover {
        .payment-bank-name {
            &__trashIcon,
            &__editIcon {
                opacity: 1;
            }
        }
    }
}