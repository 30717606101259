.dh-badge-role {
  padding: 4px 8px;
  border-radius: 45px;
  color: rgb(255, 255, 255);
  font-family: 'Montserrat-Semibold';
  font-size: 14px;
  &.cliente {
    background: rgb(83, 109, 254);
  }
  &.cuidador {
    background: rgb(60, 212, 160);
  }
  &.cliente-cuidador {
    background: rgb(154, 183, 16);
  }
  &.proveedor {
    background: rgb(144, 19, 254);
  }
  &.admin {
    background: rgb(37, 36, 56);
  }
  &.error {
    background: rgb(229, 57, 53);
  }
}