.badge-exam {
  margin-left: 0.5rem;
  &__success {
    background-color: rgb(67, 160, 71);
    border-radius: 6px;
    padding: 0.2rem 0.5rem;
  }
  &__error {
    background-color: rgb(229, 57, 53);
    border-radius: 6px;
    padding: 0.2rem 0.5rem;
  }
  span {
    font-size: 14px;
    color: var(--textColorWhite);
  }
}

.card-cuidador-exam {
  min-height: 260px;
  &__title {
    color: var(--textColor);
    font-family: 'Montserrat-Semibold';
    font-size: 19px;
    line-height: 20px;
    letter-spacing: 0.1px;
    padding: 8px 0;
  }
  &__body {
    display: flex;
    align-items: center;
    background-color: #f2f2f4;
    padding: 0.8rem 1rem;
    border-radius: 8px;
    margin-bottom: 1.5rem;
    svg {
      width: 64px;
      height: 64px;
      margin-right: 1rem;
    }
    p {
      color: var(--textColorDark);
      span {
        background-color: rgb(229, 57, 53);
        color: #fff;
        padding: 0 2px;
      }
    }
  }
  &__link {
    margin-bottom: 1.5rem;
    a:hover {
      color: var(--accentColor);
    }
  }
}