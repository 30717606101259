.checkout-wrapper {
  .container {
    margin: 20px auto;
    .checkout-grid {
      padding: 24px;
    }
    .summery-grid {
      padding: 15px;
      .summery-title {
        text-align: center;
      }
      .summary-section-title {
        margin-top: 20px;
      }
      .summary-text {
        line-height: 30px;
      }
      .invoice-text {
        line-height: 30px;
        .invoice-row {
          display: flex;
          justify-content: space-between;
        }
        .row-description {
          line-height: 20px;
          font-weight: initial;
          font-size: 12px;
        }
      }
      .subtotal-text {
        margin-top: 20px;
        text-align: right;
      }
    }
  }
  .MuiOutlinedInput-input {
    padding: 13.5px 14px;
  }
}
