
.admin-main {
  display: flex;
  header {
    background-color: var(--primaryColor);
    h1 {
      font-family: 'Montserrat-Semibold';
    }
  }
  .list-appbar {
    color: var(--textColor);
    text-decoration: none;
    span {
      font-family: 'Montserrat-Medium';
    }
  }
  &__content {
    background-color: var(--backgroundColorAdmin);
    height: 100vh;
    overflow: auto;
    flex-grow: 1;
    .space {
      min-height: 64px;
    }
    @media (min-width: 600px) {
      .space {
        min-height: 72px;
      }
    }
  }
  &__container {
    margin-top: 2rem;
    margin-bottom: 1.5rem;
  }
}