.cuidador-res-detail {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  background-color: var(--backgroundColorGray);
  min-height: 52.5vh;
  &__title {
    padding-bottom: 1rem;
  }
  .res-detail {
    &__body {
      &--item {
        margin-bottom: 0.8rem;
        p {
          color: var(--textColorLigth);
          padding-bottom: 2px;
        }
        h4 {
          color: var(--textColorDark2);
        }
      }
      &--item-price {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        div {
          display: flex;
          align-items: center;
          flex-wrap: wrap;
          &:not(:first-child) {
            margin-left: 2rem;
          }
          p {
            color: var(--textColorLigth);
          }
          h3 {
            color: var(--textColorDark2);
            font-family: 'Montserrat-Bold';
            font-size: 1.3rem;
            padding-left: 1rem;
          }
        }
      }
      @media (max-width: 641px) {
        &--item-price > div > h3 {
          padding-left: 0;
        }
      }
      @media (min-width: 960px) {
        &--grid-1 {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 16px;
        }
      }
    }
  }
  .info-detail {
    &__title-section {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
    &__body {
      &--item {
        margin-bottom: 0.8rem;
        p {
          color: var(--textColorLigth);
          padding-bottom: 2px;
        }
        h4 {
          color: var(--textColorDark2);
        }
      }
      @media (min-width: 960px) {
        &--grid-1 {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 16px;
        }
        &--grid-2 {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 16px;
        }
      }
    }
    &__grid-pets {
      &__toolbar {
        overflow-x: auto;
        justify-content: space-between;
      }
      &__body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
        padding: 0.5rem;
        &--item {
          min-width: 180px;
          overflow: hidden;
          display: flex;
          align-items: center;
          background-color: #f2f2f4;
          border-top-left-radius: 36px;
          border-bottom-left-radius: 36px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          transition: 0.2s ease;
          padding: 0 0;
          .MuiAvatar-root {
            width: 72px;
            height: 72px;
            color: var(--colorPrimary);
            margin-right: 0.8rem;
            background-color: #d4d4d4;
            transition: 0.2s ease;
          }
          &:hover {
            transform: scale(1.06, 1.06);
          }
        }
      }
    }
  }

  .container {
    .message-wrapper {
      padding-bottom: 10px;
      height: 95%;
      min-height: 640px;
      .str-chat-channel-list,
      .str-chat-channel {
        height: 600px;
        margin: auto;
      }
      .str-chat__container {
        flex-direction: column;
      }
    }
  }
}
