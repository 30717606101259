.admin-main {
  .dh-admin-housers {
    &__section-header {
      padding-bottom: 0.5rem;
    }
    &__options-housers {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      box-sizing: border-box;
      align-items: center;
      padding-bottom: 1.5rem;

      &__right {
        display: flex;
        align-items: center;

        &__button {
          margin-left: 8px;
        }
      }
    }
    &__table-housers {
      &--count-housers {
        color: var(--textColor);
        margin-top: 0px;
        margin-bottom: 0.5rem;
        font-size: 13px;
        font-family: "Montserrat-Medium";
        line-height: 18px;
        letter-spacing: -0.04px;
      }
      &--layout {
        width: 100%;
        overflow-x: auto;
        background-color: var(--white);
      }
      .table-head {
        background-color: #fafafa;
        th {
          font-family: "Montserrat-Semibold";
        }
      }
      .table-body {
        tr:hover {
          background-color: var(--hoverTableAdmin);
        }
        td {
          font-family: "Montserrat-Medium";
        }
        &__client-action {
          .MuiIconButton-root {
            color: #3f51b5;
            padding: 0.4rem;
          }
        }
        .user-name-content {
          display: flex;
          align-items: center;
          max-width: 180px;
          &__img {
            margin-right: 0.5rem;
            width: 45px;
            height: 45px;
          }
          &:hover {
            font-weight: 600;
          }
        }
      }
    }
  }
}
