.dh-calendar-detail{
    border-right: 1px solid var(--borderColor);
    border-left: 1px solid var(--borderColor);
    
    &__dates{
        border: 1px solid var(--borderColor);
        margin: 2em;
        padding: .5em;
        min-height: 20px;
    }
}
.dh-calendar-form{
    text-align: left;
    padding: 1em;
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    legend{
        color: var(--textColor);
        font-weight: bold;

    }
    .MuiFormGroup-row{
        flex-direction: column;
        width: 300px;
        .MuiFormControlLabel-labelPlacementStart{
            justify-content: space-between;
        }
        .MuiTypography-body1{
            color: var(--textColor);
            font-size: .9em;
            
        }
    }
    .MuiRadio-colorPrimary.Mui-checked{
        color: var(--primaryColor);
    }
}
.dh-calendar-actions{
    padding: 1em;
    display: flex;
    justify-content: space-evenly;

    button{
        margin-left: 10px;
    }
}