.houser-list-404 {
  padding: 3rem 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  &__image {
    margin-bottom: 1rem;
    text-align: center;
    img {
      width: 70%;
      user-select: none;
    }
  }
  h3 {
    font-size: 1.5rem;
    text-align: center;
  }
}