.dh-register-houser {
  .img-logo {
    width: 300px;
    height: 80px;
    margin-top: 16px;
    margin-left: 16px;
  }
  @media only screen and (max-width: 601px) {
    .img-logo {
      display: none;
    }
  }
  .btn-register {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-size: 1rem;
  }
  .image-register-houser {
    background-repeat: no-repeat !important;
    background-size: cover !important;
    background-position: center !important;
  }
  .dh-city-selector {
    .MuiInputBase-formControl {
      height: 55px;
    }
  }
}
