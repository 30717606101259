.register-steps {
  padding-top: 4rem;
  // height: (722px - 64px);
  // background-color: #f2f2f4;
  // padding-bottom: 1rem;
  .MuiMobileStepper-root {
    width: 100%;
    height: 40px;
    padding: 0;
    background: #fafafa;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.4);
    position: fixed;
    z-index: 10;
  }

  .MuiMobileStepper-progress {
    width: 100%;
    height: 100%;
  }

  .dh-multistep {
    padding-top: 2.5rem;
  }

  &__title-content {
    margin-bottom: 1.5rem;
    h2 {
      font-size: 2rem;
      font-family: 'Montserrat-Bold';
      letter-spacing: 0.0075em;
      &.center {
        text-align: center;
      }
    }
    p {
      padding-top: 0.5rem;
    }
  }

  .dh-multistep-list {
    background-color: #eaeaea;
    // border-right: 1px solid rgba(0, 0, 0, 0.12);
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    .MuiPaper-root {
      background-color: #fff;
      padding: 0;
    }
    .MuiStepLabel-root {
      padding: 1.2rem 1rem;
      // margin-bottom: 0.1rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    }
    .MuiStep-completed {
      .MuiStepLabel-root {
        background-color: var(--primaryColor);
      }
      .MuiStepLabel-label.MuiStepLabel-completed {
        color: var(--white);
      }
    }
    .MuiStepConnector-root {
      display: none;
    }
    .Mui-disabled {
      background-color: #eaeaea;
    }
    .step-content {
      .MuiStepIcon-root {
        &.MuiStepIcon-active {
          color: var(--primaryColor);
        }
        &.MuiStepIcon-completed {
          color: var(--white);
        }
      }
      .MuiTypography-root {
        font-family: 'Montserrat-Semibold';
      }
    }
    @media only screen and (max-width: 960px) {
      & {
        display: none;
      }
    }
  }

  .dh-multistep-form {
    padding-top: 1.5rem;
    .flex-outer {
      width: 100%;
      list-style-type: none;
      padding: 0;
      margin: 0;
      max-width: 900px;
      background: #fff no-repeat 50%;
      border-radius: 0.5rem;
      li {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        margin: 0.5rem 0;
        label {
          padding: 8px 8px 8px 0;
          color: var(--textColor);
          font-family: 'Montserrat-Semibold';
          font-size: 1.17em;
          letter-spacing: 0.02em;
          flex: 0 0 160px;
          max-width: 220px;
        }
        input {
          padding-left: 0.6rem;
          padding-right: 0.6rem;
          padding-bottom: 0.8rem;
          padding-top: 0.8rem;
          color: var(--textColor);
          font-family: 'Montserrat-Medium';
          letter-spacing: 0.08em;
        }
        .MuiTextField-root {
          width: 80%;
        }
        @media only screen and (max-width: 928px) {
          .MuiTextField-root {
            width: 100%;
          }
        }
        &.more-margin {
          margin: 1.5rem 0;
        }
      }
    }
    .photo-publication-container {
      .img-container {
        text-align: center;
        width: 95%;
        height: 200px;
        .publication-img {
          max-width: 100%;
          height: 100%;
          text-align: center;
          object-fit: cover;
          border: 2px dashed #ccc;
          border-radius: 4px;
        }
        .drop-zone {
          cursor: pointer;
          text-align: center;
          border: 2px dashed #ccc;
          border-radius: 4px;
          display: table;
          width: 100%;
          height: 100%;
          background-color: #e8ebed;
          &__content {
            display: table-cell;
            vertical-align: middle;
            p {
              margin: 0 1.5rem;
              position: relative;
              top: -16px;
              color: var(--textColor);
              font-family: 'Montserrat-Medium';
              font-size: 14px;
              line-height: 1.6;
              img {
                width: 48px;
                height: 48px;
                position: relative;
                top: 28px;
                margin-right: 0.5rem;
              }
              @media only screen and (max-width: 1100px) {
                & {
                  top: 0;
                  img {
                    display: none;
                  }
                }
              }
            }
          }
        }
        .image-progress {
          text-align: center;
          width: 100%;
          height: 200px;
          position: relative;
          img {
            opacity: 0.5;
          }
          &__circle {
            position: absolute;
            left: 47%;
            top: 38%;
            color: var(--primaryColorDark);
          }
        }
        @media only screen and (max-width: 1100px) {
          & {
            width: 100%;
          }
        }
      }
      .form-grid {
        display: flex;
        align-items: center;
      }
      .form-container {
        width: 100%;
        #upload-photo-publi {
          opacity: 0;
          display: none;
          z-index: -1;
        }
        .btn-primary {
          margin: 1rem 0;
        }
      }
    }
    .your-photo-container {
      margin-top: 20px;
      .your-photo {
        border: 3px dashed #eee;
        width: 96%;
        height: 160px;
        display: block;
        object-fit: cover;
        text-align: center;
      }
    }
    .terms-condition {
      margin: 1.5rem 6rem;
      text-align: justify;
      color: var(--textColor);
      font-family: 'Montserrat-Medium';
      font-size: 14px;
      line-height: 1.6;
      @media only screen and (max-width: 1100px) {
        & {
          margin: 1rem;
        }
      }
    }
    .btn-container {
      margin-top: 1.5rem;
      margin-bottom: 2rem;
      width: 100%;
      text-align: right;
      &.less-margin {
        margin-top: 0.5rem;
      }
      @media only screen and (max-width: 601px) {
        & {
          margin-bottom: 4rem;
        }
      }
    }
  }
}
