.cuidador-ubicacion {
  .menu {
    width: 200px;
  }
  .map-container {
    height: 60vh;
    width: 100%;
    margin-top: 1rem;
  }
  button {
    margin-top: 1.5rem;
  }
}