.root {
  margin: 18px 0 24px;
  & > :not(style) + :not(style) {
    margin: 16px 0px 0px;
  }
}
.label {
  margin-bottom: 6px;
}
.space {
  display: flex;
  flex-direction: row;
  gap: 16px;
  & > * {
    flex-grow: 1;
  }
}
