.dh-badge-simple-role {
  padding: 0;
  color: rgb(255, 255, 255);
  font-family: 'Montserrat-Semibold';
  font-size: 15px;
  &.cliente {
    color: rgb(83, 109, 254);
    text-shadow: 0 0 0.8px rgb(83, 109, 254);
  }
  &.cuidador {
    color: rgb(60, 212, 160);
    text-shadow: 0 0 0.8px rgb(60, 212, 160);
  }
  &.cliente-cuidador {
    color: rgb(154, 183, 16);
    text-shadow: 0 0 0.8px rgb(154, 183, 16);
  }
  &.proveedor {
    color: rgb(144, 19, 254);
    text-shadow: 0 0 0.8px rgb(144, 19, 254);
  }
  &.admin {
    color: rgb(37, 36, 56);
    text-shadow: 0 0 0.8px rgb(37, 36, 56);
  }
  &.error {
    color: rgb(229, 57, 53);
    text-shadow: 0 0 0.8px rgb(229, 57, 53);
  }
}