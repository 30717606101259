.dh-card-service-extra {
  display: flex;
  justify-content: space-between;
  text-align: center;
  align-items: center;
  flex-wrap: wrap;
  padding: 5rem;
  // background-image: linear-gradient(rgba(63, 63, 63, 0.6), rgba(0, 0, 0, 0.6)), url('https://galery-doghouser.s3-us-west-2.amazonaws.com/assets/pet-friendly/banner-petfriendly.jpeg');
  background-size: cover;
  background-position: center center;
  box-shadow: 2px 4px 16px rgba(0, 0, 0, 0.4);
  border-radius: 16px;
  &__info {
    h3 {
      font-size: 1.2rem;
      color: white;
      text-shadow: 2px 2px 10px rgba(0, 0, 0, 0.7);
      margin: 0;
    }
  }
  &__action {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem;
    background: rgba(100, 100, 100, 0.4);
    // border: 2px solid var(--borderColor);
    box-shadow: 0 3px 15px rgba(51, 51, 51, 0.3);
    border-radius: 5px;
    transition: 0.2s ease;
    cursor: pointer;
    &:hover {
      transform: scale(1.04, 1.04);
      box-shadow: 0 4px 16px rgba(51, 51, 51, 0.5);
    }
    &__name {
      font-size: .8em;
      text-align: left;
      margin-right: 1.5rem;
      padding: 10px 0;
      h3 {
        margin: 0;
        padding-bottom: 5px;
        color: #FFF;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
      }
      p {
        font-size: 1em;
        text-align: left;
        color: #FFF;
        text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.7);
      }
    }
  }
  @media (max-width:768px) {
    padding: 3rem;
    justify-content: center;
    &__info {
      margin-bottom: 1rem;
    }
    &__action {
      width: 100%;
    }
  }
}
