.dh-all-services {
  .dh-divider {
    width: 100%;
    height: 60px;
    background-image: url('../../assets/images/divider.png');
    background-repeat: repeat;
    background-position: 20px 10px;
    @media (max-width: 768px){
      & {
        height: 0;
      }
    }
  }
  .list-services {
    padding-bottom: 3rem;
    padding-top: 1rem;
    &__title {
      color: var(--textColor);
      margin: 0;
      padding-top: 1rem;
      padding-bottom: 1.5rem;
    }
  }
  .dh-section-more {
    display: flex;
    align-items: center;
    .dh-blue-dog {
      width: 80px;
      height: 80px;
      margin: 0;
      background-image: url('../../assets/images/dog-green.png');
      background-repeat: no-repeat;
      background-size: contain;
    }
    h3 {
      font-family: 'Montserrat-Medium';
      font-size: 1.5em;
      padding-left: 1rem;
      margin: 0;
    }
  }
  .dh-odi-hero {
    position: relative;
    height: 500px;
    background-image: url("../../assets/images/dog_more_info_2130x828.jpg");
    background-position: center center;
    background-size: cover;
    .dh-odi-field {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      padding: 40px;
      .dh-text-odi {
        font-size: 1.8em;
        padding-bottom: 1rem;
      }
      button {
        color: var(--textColor);
        text-align: end;
      }    
    }
    @media (max-width: 768px){
      .dh-odi-field {
        text-align: center;
        align-items: center;
      }
    }
  }
}