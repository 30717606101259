.page-404-container {
  position: relative;
	width: 100%;
	min-height: 100vh;
	color: var(--foregroundColor);
	background: var(--backgroundColor);
	text-align: left;
	display: grid;
	grid: repeat(4,25%) / repeat(4,25%);
  overflow: hidden;
  grid-row: 1;
  grid-column: 1 / -1;
  .page-404 {
    grid-column: 1 / -1;
    grid-row: 1 / 5;
    text-align: center;
    background: var(--canvasColor);
    position: relative;
    h1 {
      font-size: 6rem;
      color: var(--foregroundColor);
      margin: 16px 0 -40px;
    }
    img {
      width: 100%;
      height: 40%;
      margin: 2em auto 1em;
      max-width: 600px;
      object-fit: contain;
    }
    h3 {
      margin-top: 0.5rem;
      position: relative;
      z-index: 5;
    }
    .btn-primary {
      font-family: 'Montserrat-Semibold';
      font-size: 1rem;
      margin-top: 1.2em;
      z-index: 20;
      display: inline-flex;
      svg {
        margin-right: 0.5rem;
      }
    }
  }
  @media (min-width: 768px) {
    .page-404 {
      h1 {
        position: absolute;
        left: 50%;
        margin: auto auto auto -2.5em;
        top: 18%;
        z-index: 3;
        font-size: 7rem;
      }
      img {
        height: 70%;
      }
    }
  }
}