.dh-onboarding {

    & .background {
        width: 100%;
        height: 60px;
        background-image: url('../../assets/images/onboarding/top-background.png');
        background-repeat: repeat-x;
    }

    & .spacer {
        height: 20px;
    }

    & .container {
        display: flex;
        margin-top: 12px;
        justify-content: center;
        flex-direction: column;
        align-items: center;
        padding-bottom: 50px;



        & .btn-step {
            width: 200px;
            height: 40px;
            background: #80CDC5;
            border-radius: 30px;
            text-align: center;
            justify-content: center;
            align-items: center;
            display: flex;
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 20px;
        }

        & .btn-step-large {
            height: 65px;
            word-wrap: break-word;
            white-space: normal;
        }

        & .next-add-container {
            display: flex;
            flex-direction: row;

            @media only screen and (max-width: 615px) {
                flex-direction: column;
                justify-content: center;
                align-items: center;
            }
        }

        & h1 {
            font-family: 'Poppins';
            font-size: 40px;
            position: relative;
            width: 100%;
        }

        & h4 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 24px;
        }

        & .btn-volver-skip-container {
            flex-direction: row;
            display: flex;
            justify-content: space-between;
            width: calc(100% - 40px);
            margin-bottom: 2em;
            margin-left: 20px;
            margin-right: 20px;

            @media only screen and (max-width: 615px) {
                width: 100%;
            }
        }

        & .btn-volver {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 0px;
            color: #80CDC5;
            align-self: flex-start;
            padding-left: 10px;
            cursor: pointer;
        }

        & .btn-skip {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 0px;
            color: #80CDC5;
            align-self: flex-end;
            padding-right: 10px;
            cursor: pointer;
        }

        & input {
            box-sizing: border-box;

            width: 328px;
            height: 51px;

            display: flex;
            flex-direction: row;
            align-items: center;
            padding: 0px 12px;

            border: 1px solid #E0E0E0;
            border-radius: 50px;

            color: #BDBDBD;

            font-family: 'Poppins';
            font-style: normal;
            font-weight: 400;
            font-size: 18px;
            line-height: 28px;
        }

        & .error {
            border: 2px solid red;
        }

        & .error-message {
            color: red;
            margin-top: 2px;
        }


        & .mascotasSlider {
            display: flex;
            flex-direction: 'row';
            align-items: center;
            justify-content: center;
            margin-top: 24px;
            height: 260px;

            & .mascota {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
            }

            & .mascota-first {
                & img {
                    height: 150px;
                    width: 120px;
                    object-fit: contain;
                    opacity: 0.6;
                }
            }

            & .mascota-last {
                & img {
                    height: 150px;
                    width: 120px;
                    object-fit: contain;
                    opacity: 0.6;
                }
            }

            & .select-mascota {
                margin: 0px 10px;
                cursor: pointer;
            }
        }

        & .sex-buttons-container {
            display: flex;
            flex-direction: 'row';
            margin-top: 24px;

            @media only screen and (max-width: 615px) {
                flex-direction: column;
            }

            & .sex-button {
                width: 199px;
                height: 40px;
                border-radius: 30px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                line-height: 20px;
                text-align: center;
                letter-spacing: 0.5px;
                color: #FFFFFF;
                margin: 0 10px;

                @media only screen and (max-width: 615px) {
                    margin: 10px 0px;
                }

                cursor: pointer;

            }

            & .male {
                background: #FDAF3F;
            }

            & .female {
                background: #FF7376;
            }
        }

        & .tamano-selector {
            display: flex;
            flex-direction: row;

            @media only screen and (max-width: 615px) {
                flex-direction: column;
            }

            & .tamano-card {
                width: 510px;
                height: 350px;

                @media only screen and (max-width: 615px) {
                    width: 100%;
                }

                background: #FFFFFF;
                box-shadow: 0px 8px 8px rgba(35, 31, 32, 0.25);
                border-radius: 20px;
                display: flex;
                justify-content: center;
                flex-direction: column;

                & .MuiStepper-horizontal {
                    background-color: transparent;
                }

                & h3 {
                    margin-top: 9px;
                    text-align: center;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 900;
                    font-size: 20px;
                    line-height: 24px;
                    text-transform: uppercase;
                }

                & img {
                    height: 195px;
                    object-fit: contain;
                }

                & .small {
                    margin-top: 110px;
                    height: 85px;
                    object-fit: contain;
                }
            }
        }

        & .comodidades-selector {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            @media only screen and (max-width: 615px) {
                flex-direction: column;
            }

            & .comodidades-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 340px;
                margin: 0px 10px;

                @media only screen and (max-width: 615px) {
                    margin: 10px 0px;
                }

                height: 320px;
                background: #FFFFFF;
                box-shadow: 0px 8px 8px rgba(35, 31, 32, 0.25);
                border-radius: 20px;
                cursor:pointer;

                & img {
                    max-height: 196px;
                    object-fit: contain;
                    margin-bottom: 18px;
                }

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 24px;
                color: #0D4F68;
            }

            & .card-selected {
                border: 5px solid #80CDC5;
            }
        }

        & .amiguero-selector {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            @media only screen and (max-width: 615px) {
                flex-direction: column;
            }

            & .amiguero-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 340px;
                margin: 0px 10px;

                @media only screen and (max-width: 615px) {
                    margin: 10px 0px;
                }

                height: 350px;
                background: #FFFFFF;
                box-shadow: 0px 8px 8px rgba(35, 31, 32, 0.25);
                border-radius: 20px;
                cursor:pointer;

                & img {
                    height: 150px;
                    object-fit: contain;
                    margin-bottom: 28px;
                }

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 24px;
                color: #0D4F68;
            }

            & .card-selected {
                border: 5px solid #80CDC5;
            }
        }

        & .servicio-selector {
            display: flex;
            flex-direction: row;
            justify-content: space-around;

            @media only screen and (max-width: 615px) {
                flex-direction: column;
            }

            & .servicio-card {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 254px;
                height: 350px;
                margin: 0px 10px;

                @media only screen and (max-width: 615px) {
                    margin: 10px 0px;
                    width: 320px;
                }

                background: #FFFFFF;
                box-shadow: 0px 8px 8px rgba(35, 31, 32, 0.25);
                border-radius: 20px;
                cursor:pointer;

                & img {
                    height: 150px;
                    object-fit: contain;
                    margin-bottom: 28px;
                }

                font-family: 'Poppins';
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 24px;
                color: #0D4F68;

                & p {
                    margin-top: 6px;
                    color: #0D4F68;
                }
            }

            & .card-selected {
                border: 5px solid #80CDC5;
            }
        }


        & .pet-card {
            margin: 5px 5px;
            position: relative;
        }

        & .nombre-mascota {
            margin-top: 8px;
            width: 100%;
        }

        & .especie-icon.active {
            color: var(--primaryColor)
        }

        & .tamano-card {
            margin: 5px 5px;
            height: 170px;
            width: 170px;
            cursor: pointer;

            &.active {
                border: 4px solid var(--primaryColor)
            }
        }

        & .results-container {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;

            @media only screen and (max-width: 615px) {
                flex-direction: column;
                justify-content: center;
            }

            width: 100%;

        }

        & .results-summary {
            width: 50%;
            padding: 0px 12px;
            display: flex;
            justify-content: flex-start;
            flex-direction: column;

            @media only screen and (max-width: 615px) {
                width: 100%;
                padding: 0;
            }

            & .map-button-container {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-top: 30px;

                & .btn-primary {
                    width: 230px;
                    height: 40px;
                    background: #80CDC5;
                    border-radius: 30px;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    display: flex;
                    font-family: 'Poppins';
                    font-style: normal;
                    font-weight: 600;
                    font-size: 16px;
                    line-height: 20px;
                }
            }
        }

        & .result-mascota {
            margin-top: 32px;
            max-width: 340px;
            align-self: center;

            & .title-container {
                flex-direction: row;
                display: flex;
                align-items: center;
            }

            & img {
                border-radius: 30px;
            }

            & .title {
                margin-left: 8px;
                display: inline;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 900;
                font-size: 40px;
                line-height: 60px;
                text-transform: uppercase;
                color: #4F4F4F;
            }

            & .subtitle {
                margin-top: 8px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 900;
                font-size: 20px;
                line-height: 24px;
                color: #4F4F4F;
                text-transform: uppercase;
            }

            & .mascota-description {
                margin-top: 22px;
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 18px;
                line-height: 26px;
                letter-spacing: 0.005em;
                color: #0D4F68;

                & b {
                    font-weight: 600;
                }
            }
        }

        & .houses-container {
            width: 50%;
            display: flex;
            flex-direction: row;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            @media only screen and (max-width: 615px) {
                margin-top: 24px;
                width: 100%;
            }
        }

        & .result-card {
            width: 260px;
            margin: 6px;

            @media only screen and (max-width: 615px) {
                width: calc(100% - 4px);
                margin: 6px 2px;
            }

            & a.link-cuidador {
                & h4 {
                    color: var(--primaryColor)
                }
            }

            & .location {
                display: flex;
                align-items: center;
                margin: .2rem 0;

                svg {
                    width: 1.2rem;
                    height: 1.2rem;
                }

                span {
                    font-family: 'Montserrat-Semibold';
                    font-size: 0.85rem;
                }
            }
        }
    }
}