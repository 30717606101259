.dh-grid-bank-account {
  &__new-payment {
    height: 250px;
    background-color: #e8ebed;
    cursor: pointer;
    border-radius: .5rem;
    border: 2px dashed #bdbdbd;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 40px;
      height: 40px;
    }
  }
}
