.houser-header {
  text-align: center;
  position: fixed;
  width: 100%;
  padding-top: 0.4rem;
  background-color: var(--white);
  box-shadow: 0 2px 8px -2px rgba(51, 51, 51, 0.2);
  z-index: 1020;

  .menu-icon {
    display: none;
    margin-top: 0.1rem;
  }
  .menu-logo {
    float: left;
    padding: 0;
    text-decoration: none;
    transition: .5s all;
    &__image {
      margin-top: 8px;
      width: 50px;
      height: 40px;
    }
  }
  .menu-left {
    float: left;
    margin-left: 1rem;
    ul {
      list-style: none;
      margin: 0;
      padding: 0;
      li {
        display: inline-block;
        padding: 1.7rem 0 1.1rem 0;
        a {
          position: relative;
          text-decoration: none;
          padding: 1.7rem 0 0.7rem 0;
          margin: 0 12px;
          color: var(--textColor);
          &:hover {
            color: var(--primaryColor);
          }
          &:focus,
          &:active {
            color: var(--primaryColor);
          }
          &.active {
            color: var(--primaryColor);
            &::before {
              background-color: var(--primaryColor);
              transform-origin: center top;
              transform: scale(1, 1);
            }
          }
          &::before {
            content: '';
            margin: 0 12px;
            display: block;
            position: absolute;
            top: 100%;
            height: 6px;
            width: 100%;
            background-color: var(--primaryColor);
            transform-origin: center top;
            transform: scale(0, 1);
            transition: color 0.2s, transform 0.2s ease-out;
          }
          &:active::before {
            background-color: var(--primaryColor);
          }
          &:hover::before,
          &:focus::before {
            transform-origin: center top;
            transform: scale(1, 1);
          }
        }
      }
    }
  }
  .menu-right {
    list-style: none;
    clear: none;
    max-height: none;
    float: right;
    margin: 0;
    padding: 0;
    li {
      padding-top: 0.6rem;
    }
    .dh-user-logged {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      padding-bottom: 0.4rem;
      margin: 0;
      &__name {
        margin-right: 8px;
        text-decoration: none;
        p {
          color: var(--textColor);
          font-family: 'Montserrat-Semibold';
          font-size: 1rem;
        }
        &:hover {
          color: var(--primaryColor);
        }
      }
      &__ico {
        width: 42px;
        height: 42px;
        .profile-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          text-align: center;
        }
      }
    }
  }
  @media only screen and (max-width: 916px) {
    & {
      padding: 0.5rem 0;
    }
    .menu-icon {
      display: block;
    }
    .menu-logo {
      float: right;
    }
    .menu-left,
    .menu-right {
      display: none;
    }
  }
}

.cuidador-list-item {
	color: var(--textColor);
	text-decoration: none;
}
