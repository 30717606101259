.cuidador-notification-layout {
    padding-top: 1.5rem;
    padding-bottom: 2rem;
    background-color: var(--backgroundColorGray);
    min-height: 52.5vh;
}

@media (max-width:768px){
.dh-cuidador-notificacion{
    // .MuiGrid-grid-xs-2:first-of-type{
    //     background-color: var(--primaryColor)!important;
    // }
    &__header{
        h4{
            margin: .6rem 1em 0em 0;
            font-size: .9em;
        }
        p{
            margin: .5rem 0 0 0;
            font-size: .8em;
        }
        &__hora{
            display: flex;
            text-align: center;
            justify-content: flex-start;
            align-items: center;
            margin: 1em 0 1em 1em;
            border-bottom: .5px solid var(--borderColor);
        }
    }
    &__body{
        display: flex;
        justify-content: space-around;
    }
    &__action{
            display: flex;
            justify-content: space-around;
            padding: .4em;
            h3{
                cursor: pointer;
                color: var(--primaryColor);

            }
        }
}
}

@media (min-width:768px){
.dh-cuidador-notificacion{
    display: flex;
    justify-content: space-evenly;
    flex-wrap: wrap;
    margin-top: .8em;
    // padding: .5em;
    border: 1px solid var(--borderColor);
    border-radius: 20px;
    margin-bottom: 1em;
    cursor: pointer;
    transition: background .2s ease-in-out;
    &:hover{
        background-color: var(--color-background-alt);
    }
    // .MuiGrid-grid-xs-2:first-of-type{
    //     background-color: var(--primaryColor);
    //     svg{
    //         color : var(--white)!important;
    //     }
    // }
    &__icon{
        flex: 20%;
        text-align: center;
        display: block;
        padding: 1.2em;
    
    }
    &__header{
        display: flex;
        justify-content: space-between;
        flex: 80%;
        h4{
            margin: .1rem 1em .6em 0;
            font-size: .9em;
        }
        p{
            margin: 0 0 .4em 0;
        }
        hr{
            margin-bottom: 1em;
            margin-top: 0;
        }
        &__hora{
            display: flex;
            text-align: flex;
            align-items: center;
        }
    }
    &__body{
        p{
            font-size: .9em;
        }
    }
    &__action{
        text-align: center;
        display: block;
        padding: 1.2em;
        svg{
            transition: all ease-in-out .2s;
            &:hover{
                cursor: pointer;
                color: var(--primaryColor)!important;

            }
        }
    }
}
}

.cuidador-notificaciones-mobile{

    &__appbar {
        position: fixed;
        z-index: 1019;
        background-color: var(--white);
        box-shadow: 0 6px 8px -2px rgba(51, 51, 51, 0.2);
        width: 100%;
        padding-top: 0rem;
      }
    &__contain{
        padding-top: 3rem;
        min-height: 250px;
    }
}
