.root {
  box-shadow: rgba(145, 158, 171, 0.2) 0px 0px 2px 0px,
    rgba(145, 158, 171, 0.12) 0px 12px 24px -4px;
  background-color: var(--backgroundColor);
  border: 1px solid var(--borderColor);
  border-radius: 8px;
  display: flex;
  position: relative;
  overflow: hidden;
  transition: 0.2s ease;
  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0 2px 12px rgba(255, 171, 0, 0.4);
    border: 1px solid rgb(255, 171, 0);
  }
  @media only screen and (max-width: 1295px) {
    & {
      flex-direction: column;
    }
    .coverImage {
      flex: none;
      img {
        display: block;
      }
    }
    .body {
      width: 100%;
      .header {
        padding: 1rem 0.5rem 1rem 0.5rem;
      }
      .bottom {
        padding: 0 0.5rem 0.5rem 0.5rem;
      }
    }
  }
  @media only screen and (max-width: 740px) {
    .bottom {
      .wrap {
        justify-content: flex-end;
      }
    }
  }
}
.coverImage {
  flex: 0 0 50%;
  width: 100%;
  height: 280px;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    user-select: none;
  }
}
.body {
  width: 50%;
  flex: 0 0 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.header {
  padding: 1rem 1rem 0.5rem 1rem;
  .subHeader {
    font-size: 0.9rem;
    font-weight: var(--fontWeightSemibold);
    color: var(--textColor);
    margin-bottom: 0.25rem;
  }
  .title {
    font-size: 1.2rem;
    font-weight: var(--fontWeightSemibold);
    color: var(--primaryColorDark2);
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.5rem;
  }
  .location {
    display: inline-flex;
    align-items: center;
    svg {
      color: var(--textColorDark2);
      width: 1.1rem;
      height: 1.1rem;
    }
    span {
      color: var(--textColorDark2);
      font-family: 'Montserrat-Semibold';
      font-size: 0.85rem;
    }
  }
  .price {
    margin-top: 0.25rem;
    color: var(--textColor);
    font-weight: var(--fontWeightSemibold);
    font-size: 0.85rem;
    strong {
      font-weight: var(--fontWeightBold);
      color: var(--textColorDark2);
    }
  }
}
.bottom {
  padding: 0 1rem 1rem 1rem;
  .wrap {
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
  }
  .user {
    display: flex;
    align-items: center;
    .name {
      color: var(--textColorDark);
      font-weight: var(--fontWeightSemibold);
      margin-right: 0.8rem;
      text-align: right;
    }
    .cover {
      width: 48px;
      height: 48px;
    }
  }
}
