.root {
  position: relative;
  height: 260px;
  background: #333 no-repeat 50%;
  background-size: cover;
  border-radius: var(--borderRadiusMedium);
  box-shadow: 0 3px 8px -2px rgba(0, 0, 0, 0.15);
  overflow: hidden;
}
.wrap {
  height: 260px;
  background-image: linear-gradient(
    180deg,
    transparent,
    transparent 30%,
    rgba(0, 0, 0, 0.4) 50%,
    rgba(0, 0, 0, 0.9)
  );
  padding: 16px;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  color: #dce1e5;
}
.info {
  position: absolute;
  bottom: 3.5rem;
  left: 1.5rem;
  right: 0;
  color: #dce1e5;
}
.name {
  color: var(--white);
  font-size: 26px;
  line-height: 1.4;
}
.text {
  color: var(--white);
}
