.lost-password {
  .dh-content-block {
    margin: 2.5rem 0.5rem;
    padding: 2rem;
    border-radius: 6px;
    background: var(--white);
    box-shadow: 0 5px 20px rgba(0,0,0,0.10), 0 2px 4px rgba(0,0,0,0.15);
    p {
      line-height: 1.6;
      margin: 0.8rem 0;
    }
    button {
      margin-top: 1rem;
    }
    @media only screen and (max-width: 601px) {
      & {
        margin: 1.5rem 0;
      }
    }
  }
}