.dashboard-cuidador {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  background-color: var(--backgroundColorGray);
  min-height: 52.5vh;
  &__title {
    padding-bottom: 1rem;
  }
  &__sub-title {
    margin-bottom: 0.7rem;
  }
  .publication-summary {
    display: flex;
    flex-direction: row;
    box-sizing: border-box;
    width: 100%;
    &__image {
      width: 34%;
      height: 150px;
      margin-right: 1rem;
      img {
        width: 100%;
        height: 100%;
        border-radius: 4px;
        object-fit: cover;
        text-align: center;
        user-select: none;
      }
    }
    &__container {
      width: 66%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 1rem;
    }
    &__content {
      p {
        font-size: 14px;
        &.title {
          font-family: 'Montserrat-Bold';
          color: var(--textColor);
          font-size: 16px;
          padding-bottom: 3px;
        }
        &.distric {
          padding-top: 2px;
        }
      }
    }
    &__actions {
      p {
        font-family: 'Montserrat-Semibold';
        font-size: 14px;
        color: var(--primaryColor);
        margin: 0;
      }
      a {
        cursor: pointer;
      }
    }
    @media only screen and (max-width: 916px) {
      & {
        flex-direction: column;
      }
      &__image {
        width: 100%;
        margin-right: 0;
        margin-bottom: 0.7rem;
      }
      &__container {
        width: 100%;
        padding: 0;
      }
      &__content {
        margin-bottom: 0.5rem;
        padding-left: 1rem;
      }
      &__actions {
        text-align: start;
        padding-right: 1rem;
      }
    }
  }
  .notification-container {
    margin-bottom: 1rem;
    overflow: hidden;
	  background-color: var(--white);
    border-radius: 4px;
    border: 1px solid rgba(0, 0, 0, 0.1);
    .persistent-notification {
      border-bottom: 1px solid var(--color-border-base);
      &:last-child {
        border: none;
      }
    }
    .persistent-notification:nth-child(even) {
      background: #F8F8F8;
    }
  }
}

.dh-type-house {
  font-family: 'Montserrat-Semibold';
  font-size: 14px;
}
