.admin-main {
  &__content {
    .admin-title,
    .dh-badge-role {
      margin-right: 1rem;
    }

    .card-user-sumary {
      &__header {
        display: flex;
        justify-content: space-between;
        &__user {
          display: flex;
          align-items: center;
          .header-user-image {
            width: 64px;
            height: 64px;
            min-width: 64px;
            margin-right: 0.5rem;
            img {
              width: 100%;
              height: 100%;
              border-radius: 50%;
              object-fit: cover;
              text-align: center;
            }
          }
          .header-user-title {
            h3 {
              margin: 0 0 0.2rem 0;
            }
          }
        }
      }
      .card-body {
        margin-top: 1rem;
        &__stats {
          p {
            color: var(--textColor);
            font-size: 15px;
            font-family: 'Montserrat-Medium';
          }
          h5 {
            font: 500 15px/1.2 'Montserrat-Semibold';
            color: var(--textColorDark);
            margin: 0 0 20px;
          }
          .stats-status {
            display: flex;
            align-items: center;
            p {
              padding-right: 8px;
            }
          }
        }
      }
    }

    .card-user-location {
      &__header {
        color: var(--textColor);
        font-family: 'Montserrat-Semibold';
        font-size: 19px;
        line-height: 20px;
        letter-spacing: 0.1px;
        margin-bottom: 0.5rem;
      }
      &__body {
        .card-map {
          height: 300px;
          background-color: #e4e3df;
          img {
            width: 100%;
            height: 100%;
            object-fit: cover;
          }
        }
        .card-stats {
          p {
            color: var(--textColor);
            font-size: 15px;
            font-family: 'Montserrat-Medium';
          }
          h5 {
            font: 500 15px/1.2 'Montserrat-Semibold';
            color: var(--textColorDark);
            margin: 0 0 20px;
          }
        }
      }
    }

    .card-admin-list {
      &__header {
        align-items: center;
        font-size: 19px;
        color: var(--textColor);
        font-family: 'Montserrat-Semibold';
        line-height: 20px;
        letter-spacing: 0.1px;
      }
      &__content {
        padding: 0;
        .dh-table {
          width: 100%;
          display: table;
          border-spacing: 0;
          border-collapse: collapse;
          tr {
            color: inherit;
            display: table-row;
            outline: none;
            vertical-align: middle;
            td {
              padding-top: 1.2rem;
            }
            .td-title {
              color: var(--textColor);
              font-size: 15px;
              font-family: 'Montserrat-Medium';
              max-width: 32px;
              &.facebook {
                color: var(--facebook);
                text-shadow: 0 0 0.8px var(--facebook);
              }
              &.google {
                color: var(--google);
                text-shadow: 0 0 0.8px var(--google);
              }
            }
            .td-text {
              font: 500 15px/1.2 'Montserrat-Semibold';
              color: var(--textColorDark);
              margin: 0 0 20px;
            }
          }
        }
      }
    }

    .admin-cuidador-header {
      &__container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        align-items: center;
        &--border {
          width: 70%;
          height: 5px;
          background-color: var(--accentColor);
        }
        h2 {
          padding-bottom: 6px;
        }
      }
    }

    .card-cuidador-detail {
      &__body {
        min-height: 260px;
        &__item {
          padding-bottom: 1.2rem;
          p {
            color: var(--textColor);
            font-size: 15px;
            font-family: 'Montserrat-Medium';
            line-height: 21px;
            padding-bottom: 4px;
          }
          h5 {
            font: 500 15px/1.2 'Montserrat-Semibold';
            line-height: 21px;
            color: var(--textColorDark);
          }
          button {
            margin-top: 1rem;
          }
        }
        &__grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 16px;
          margin-bottom: 1.2rem;
          overflow-x: auto;
          .grid-item {
            display: flex;
            align-items: center;
            svg {
              width: 48px;
              height: 48px;
              min-width: 48px;
              min-height: 48px;
              margin-right: 0.5rem;
            }
            h5 {
              font: 500 15px/1.2 'Montserrat-Semibold';
              color: var(--textColorDark2);
              line-height: 21px;
            }
          }
        }
        .video-container {
          margin-bottom: 1rem;
          iframe {
            width: 100%;
            height: 350px;
            border: none;
          }
        }
      }
    }
    .gallery-container {
      min-height: 1px;
      margin-top: 1rem;
      overflow: auto;
      .admin-title {
        margin-bottom: 1.2rem;
      }
    }
    @media (min-width: 960px) {
      .card-user-sumary {
        .card-header {
          &__image {
            width: 72px;
            height: 72px;
            min-width: 72px;
            margin-right: 1rem;
          }
        }
        .card-body {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 16px;
        }
      }
      .card-user-location {
        &__body {
          display: grid;
          grid-template-columns: repeat(8, 1fr);
          grid-template-rows: repeat(6, 50px);
          grid-gap: 18px;
          .card-map {
            height: 100%;
            grid-column-start: 1;
            grid-column-end: 5;
            grid-row-start: 1;
            grid-row-end: 7;
          }
          .card-stats-1 {
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          .card-stats-2 {
            grid-column-start: 7;
            grid-column-end: 9;
            grid-row-start: 1;
            grid-row-end: 2;
          }
          .card-stats-3 {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 2;
            grid-row-end: 3;
          }
          .card-stats-4 {
            grid-column-start: 5;
            grid-column-end: 7;
            grid-row-start: 3;
            grid-row-end: 4;
          }
          .card-stats-5 {
            grid-column-start: 7;
            grid-column-end: 9;
            grid-row-start: 3;
            grid-row-end: 4;
          }
          .card-stats-6 {
            grid-column-start: 5;
            grid-column-end: 9;
            grid-row-start: 4;
            grid-row-end: 5;
          }
        }
      }
    }
  }
}
