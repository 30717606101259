.layout-img-logo {
  width: 300px;
  height: 80px;
  margin-top: 16px;
  margin-left: 16px;
}
@media only screen and (max-width: 601px) {  
  .layout-img-logo {
    display: none;
  }
}
