.cuidador-publication {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  background-color: var(--backgroundColorGray);
  min-height: 52.5vh;
  &__title {
    padding-bottom: 0.5rem;
  }
  &__sub-title {
    padding-bottom: 1rem;
  }
  .publication-card  {
    margin: 1.5rem 0;
  }
  &__photos {
    .gallery {
      .gallery__item {
        height: 144px;
        background-color: rgba(221, 221, 221, 0.6);
        border-radius: 4px;
        .gallery__img{
          width: 100%;
          height: 100%;
          border-radius: 4px;
          object-fit: cover;
        }
        .photo-overlay {
          display: none;
        }
      }
      .gallery-grid {
        &:nth-last-child(2) {
          .max {
            position: relative;
            .photo-overlay {
              display: block;
              cursor: pointer;
              border-radius: 4px;
              background: rgba(0,0,0,.7);
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              .photo-overlay-content {
                position: relative;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                left: 0;
                position: absolute;
                right: 0;
                strong {
                  color: var(--textColorWhite)
                }
                p {
                  margin: 0.5rem 0;
                  color: var(--textColorWhite);
                }
              }
            }
          }
        }
      }
    }
  }
  &__servicios {
    .servicio-container {
      display: flex;
      text-align: center;
      .servicio-item {
        width: 80px;
        text-align: center;
        margin: 0 1rem;
        svg {
          width: 64px;
          height: 64px;
        }
        p {
          color: var(--textColor);
          margin: 0.5rem 0;
        }
      }
    }
  }
  .btn-container {
    text-align: end;
    .btn-primary {
      width: 86px;
    }
  }
  @media only screen and (max-width: 615px) {
    .publication-card  {
      margin: 1rem 0;
    }
    .btn-container {
      text-align: center;
    }
    &__servicios {
      .servicio-container {
        justify-content: center;
        div {
          justify-content: center;
        }
      }
    }
  }
}