
.dh-schedule{
    overflow: hidden;
	background-color: var(--white);
    border-radius: 4px;
    border: .5px solid rgb(112, 109, 109);
	box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
	padding: 1rem;
	@media (min-width: 960px) {
		& {
			padding: 1.5rem;
		}
    }
    margin-bottom: 1em;

    &__title{

    h3{
        color: var(--primaryColor)
    }
}
}
