.servicio-item {
  width: 80px;
  text-align: center;
  margin: 1rem 1rem;
  svg {
    width: 64px;
    height: 64px;
  }
  p {
    color: var(--textColor);
    margin: 0.5rem 0;
  }
}