.dh-badge-publication {
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
  .circulo {
    width: 16px;
    height: 16px;
    -moz-border-radius: 50%;
    -webkit-border-radius: 50%;
    border-radius: 50%;
    background: #707070;
    &.success {
      background: #5cb85c;
    }
    &.error {
      background: #e53935;
    }
  }
  h4 {
    margin: 0 0 0 0.5rem;
    font-size: 16px;
  }
}
