.step-new-pet-info {
	h1 {
		margin: 0;
		padding-bottom: 0.5rem;
	}
	.new-pet-info-form {
		input {
			padding-bottom: .8rem;
			padding-top: .8rem;
			color: var(--textColor);
			font-family: 'Montserrat-Medium';
			letter-spacing: .08em;
		}
		.MuiInputBase-inputSelect {
			padding-bottom: .8rem;
			padding-top: .8rem;
			color: var(--textColor);
			font-family: 'Montserrat-Medium';
		}
		.cortar {
			overflow: hidden;
			white-space: nowrap;
			text-overflow: ellipsis;
		}
		&__type-pet {
			.form-has-pets{
				svg {
					width: 64px;
					height: 64px;
				}
				span {
					font-family: 'Montserrat-Medium';
				}
				@media only screen and (max-width: 601px) {  
					& {
						display:inline-block;
						text-align:center;
					}
				}
			}
		}
		&__size-list {
			.form-size-pet {
				label {
					height: 116px;
					max-width: 140px;
					justify-content: flex-end;
					align-items: center;
				}
				span {
					font-family: 'Montserrat-Medium';
				}
				.dog-small {
					svg {
						width: 72px;
						height: 72px;
					}
				}
				.dog-medium {
					svg {
						width: 88px;
						height: 88px;
					}
				}
				.dog-big {
					svg {
						width: 104px;
						height: 104px;
					}
				}
				@media only screen and (max-width: 601px) {  
					& {
						display:inline-block;
						text-align:center;
					}
				}
			}
		}
		.btn-step {
			margin-top: 1.5rem;
		}
	}
}