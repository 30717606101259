.root {
  display: flex;
  flex-direction: column;
}
.padding {
  padding-top: 1.6rem;
  padding-bottom: 1.4rem;
}
.title {
  color: var(--textColor);
  margin: 0;
}
.small {
  font-size: var(--fontSizeBase);
  color: var(--textColorLigth);
  margin-top: 0.6rem;
}
