.dh-admin-service-detail {
  .card-service-sumary {
    &__card-body {
      margin-top: 1rem;
      &__stats {
        p {
          color: var(--textColor);
          font-size: 15px;
          font-family: 'Montserrat-Medium';
        }
        h5 {
          font: 500 15px/1.2 'Montserrat-Semibold';
          color: var(--textColorDark);
          margin: 0 0 20px;
        }
        .stats-status {
          display: flex;
          align-items: center;
          p {
            padding-right: 8px;
          }
        }
      }
    }
  }
  @media (min-width: 960px) {
    .card-service-sumary {
      &__card-body {
        display: grid;
        grid-template-columns: repeat(5, 1fr);
        grid-gap: 16px;
      }
    }
  }
}