.dh-card-admin-places {
  min-height: 20rem;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  display:flex;
  overflow: hidden;
  border-radius: 8px;
  flex-direction: column;
  background-color: white;
  &__image {
    display: flex;
    height: 10rem;
    img {
      object-fit: cover;
      width: 100%;
      height: 100%;
      transition: ease-in-out .4s all;
    }
    &__not-fount {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 4rem;
        height: 4rem;
      }
      p {
        padding-top: 0.5rem;
        font-size: 1.1rem;
        font-family: 'Montserrat-Bold';
        text-align: center;
        color: var(--textColorDark2);
      }
    }
  }
  &__content {
    padding: .8rem;
    background-color: white;
    transition: all ease-in-out .3s;
    h3 {
      margin: 0 0 .5em 0;
      color: var(--primaryColor);
    }
    h4 {
      font-size: 0.9rem;
      padding: 0;
      margin: .5em 0;
    }
    span {
      font-size: 0.8rem;
      font-family: 'Montserrat-Regular';
    }
    &__btn {
      text-align: center;
    }
  }
}


