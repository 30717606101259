.root {
  margin-bottom: 24px;
}
.wrap {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}
.back {
  display: flex;
  align-items: flex-start;
}
.box {
  flex-grow: 1;
  display: flex;
  align-items: center;
  & > :not(style) + :not(style) {
    margin: 8px 0px 0px 0px;
  }
}
.title {
  font-size: var(--fontSize1);
  font-weight: var(--fontWeightBold);
}
.subTitle {
  padding: 0;
  font-size: var(--fontSize3);
}
.text {
  display: inline-block;
  font-size: var(--fontSize5);
  color: var(--textColorLigth);
}
.action {
  flex-shrink: 0;
  & > :not(style) + :not(style) {
    margin: 0px 0px 0px 10px;
  }
}
