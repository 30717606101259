.copyright-content {
  margin-bottom: 2rem;
  .copyright-text {
    text-align: center;
    color: var(--textColor);
    font-family: 'Montserrat-Medium';
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
  }
  .copyright-main {
    text-decoration: none;
    color: var(--textColor);
    font-size: 0.875rem;
    line-height: 1.43;
    letter-spacing: 0.01071em;
    font-family: 'Montserrat-Semibold';
  }
}