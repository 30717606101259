.dh-title-back {
  display: flex;
  align-items: center;
  text-align: center;
  box-sizing: border-box;
  width: 100%;
  .arrow-back {
    margin-right: 0.5rem;
    button {
      min-width: 32px;
    }
  }
}