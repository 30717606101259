:root {
  --canvasColor: #f9f9f9;

  --backgroundColor: #fff;
  --backgroundColorGray: #fcfcfc;
  --color-background-alt: #f7f7f7;
  --foregroundColor: #111;
  --borderRadius: 8px;
  --boxShadow: 0 2px 5px rgba(#333, 0.2);

  // Typography

  --fontFamily: 'Montserrat', sans-serif;

  --fontSize0: 2.625rem;
  --fontSize1: 2.125rem;
  --fontSize2: 1.5rem;
  --fontSize3: 1.25rem;
  --fontSize4: 1.125rem;
  --fontSize5: 1rem;
  --fontSize6: 0.875rem;
  --fontSize7: 0.75rem;
  --fontSizeBase: 0.875rem;

  --fontWeightBold: 700;
  --fontWeightRegular: 400;
  --fontWeightMedium: 500;
  --fontWeightSemibold: 600;

  // Text Color

  --textColor: #5a5655;
  --textColorLigth: #8a8989;
  --textColorDark: #000000de;
  --textColorDark2: #3f3f3f;
  --textColorWhite: #fff;
  --textColorAlt1: rgb(45, 55, 72);
  --textColorAlt2: rgb(100, 110, 115);

  // Dog Houser Colors

  --accentColor: #f9a64a;
  --primaryColor: #80cdc5;
  --primaryColorLigth: #a8d6d2;
  --primaryColorDark: #5a948e;
  --primaryColorDark2: #69acab;
  --borderColor: #ada9a8;
  --color-border-base: #d9d9de;

  // Icons Colors

  --iconColor: #5e5f5f;
  --iconColorBlack: #3f3f3f;
  --iconColorWhite: #fff;

  // Admin Vars

  --backgroundColorAdmin: #f9f9fb;
  --backgroundFilterAdmin: #fff;
  --hoverTableAdmin: #f4f6f8;

  // Button Vars

  --btnBorderRadius: 8px;
  --btnHoverColor: rgb(199, 199, 199);

  /*
  * 5. Border vars
  */

  --borderRadiusLarge: 12px;
  --borderRadiusMedium: 8px;
  --borderRadiusNone: 0;
  --borderRadiusRounded: 9000px;
  --borderRadiusSmall: 4px;

  /*
  * 6. Shadows vars
  */

  --shadowElevation1: rgba(140, 152, 164, 0.18) 0px 10px 40px 10px;

  // Colors

  --red: #f44336;
  --white: #fff;
  --gray: #555;
  --facebook: #495b95;
  --google: #df4e41;

  --colorInfo: #29b6f6;
}
