.dh-dialog-user-not-register {
  &__title {
    flex: 0 0 auto;
    margin: 0;
    padding: 1rem 1.5rem;
    h2 {
      margin: 0;
      line-height: 1.6;
      letter-spacing: 0.0075em;
    }
  }
  &__actions {
    flex: 0 0 auto;
    display: flex;
    padding: 1rem 1.5rem;
    align-items: center;
    justify-content: flex-end;
    & > * + * {
      margin-left: 1rem;
    }
  }
  p {
    font-size: 1rem;
    line-height: 1.5;
    letter-spacing: 0.00938em;
    margin-bottom: 12px;
  }
}
