.dh-card-comment {
  margin-bottom: 2rem;
  display: flex;
  &__image {
    width: 60px;
    height: 60px;
    margin-right: 1rem;
    background-color: #dddddd;
    display: flex;
    overflow: hidden;
    align-items: center;
    flex-shrink: 0;
    line-height: 1;
    user-select: none;
    border-radius: 50%;
    justify-content: center;
    &__user-img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    svg {
      width: 36px;
      height: 36px;
      fill: var(--iconColor);
    }
  }
  &__content {
    width: 100%;
    background-color: #f7f7fb;
    border-radius: 8px;
    padding: 0.6rem;
    &__header {
      width: 100%;
      margin-bottom: 0.5rem;
      align-items: baseline;
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      h3 {
        display: inline-block;
        margin: 0;
        padding-right: 0.5rem;
        font-family: 'Montserrat-Bold';
        font-size: 1rem;
      }
      span {
        font-size: .8rem;
        color: var(--textColor);
      }
    }
    &__body {
      p {
        display: block;
        font-size: 0.9rem;
      }
    }
  }
}