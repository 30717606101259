.dh-badge-status {
  color: rgb(255, 255, 255);
  padding: 8px 8px;
  border-radius: 1rem;
  min-width: 50px;
  max-width: 100px;
  text-align: center;
  font-family: 'Montserrat-Semibold';
  font-size: 13px;
  line-height: 10px;
  white-space: nowrap;
  &.verificado {
    background-color: rgb(67, 160, 71);
  }
  &.no-verificado {
    background-color: rgb(238, 168, 0);
  }
  &.error {
    background-color: rgb(229, 57, 53);
  }
}
