.card-delivery-panel {
    display: grid;
    grid: 180px auto min-content / 100%;
    border-radius: 20px;
    overflow: hidden;
    background: var(--backgroundColor);
    border: 2px solid var(--borderColor);
    box-shadow: 0 3px 15px rgba(51, 51, 51, 0.3);
    min-height: 320px;
    margin: auto;
    transition: 0.2s ease;
    &:hover {
      transform: scale(1.04, 1.04);
      box-shadow: 0 4px 16px rgba(51, 51, 51, 0.5);
    }
    &__image {
      overflow: hidden;
      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: 0.2s ease;
      }
    }
    &__info {
      padding: 0.2rem 0.9rem 0.9rem 0.9rem;
      display: flex;
      flex-wrap: wrap;
      h2 {
        flex: 50%;
        font-size: 16px;
        margin-bottom: 8px;
        color: var(--textColorDark)
      }
      span{
        display: inline-block;
        padding: 10px;
        font-size: 12px;
        font-weight: bold;
        color: var(--textColor);
        margin: 0;
      }
      p {
        flex: 100%;
        font-size: 14px;
        color: var(--textColor);
        margin: 0;
      }
      
    }
  }