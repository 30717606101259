.options {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  box-sizing: border-box;
  align-items: center;
  margin-bottom: 24px;
  .right {
    display: flex;
    align-items: center;

    .action {
      margin-left: 16px;
    }
  }
}
.count {
  color: var(--textColor);
  margin-top: 0px;
  margin-bottom: 0.5rem;
  font-size: 13px;
  font-weight: var(--fontWeightSemibold);
  line-height: 18px;
  letter-spacing: -0.04px;
}
.layout {
  width: 100%;
  overflow-x: auto;
  background-color: var(--white);
}
.head {
  background-color: #fafafa;
  th {
    font-weight: var(--fontWeightSemibold);
  }
}
.user {
  display: flex;
  align-items: center;
  .avatar {
    width: 45px;
    height: 45px;
    margin-right: 0.8rem;
  }
  .name {
    font-weight: var(--fontWeightMedium);
    &:hover {
      color: var(--primaryColorDark);
    }
  }
  .id {
    color: var(--colorInfo);
    font-weight: var(--fontWeightMedium);
  }
}
