.houser-request {
  .MuiOutlinedInput-input {
    padding: 13.5px 14px;
    font-size: 14px;
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  @media (min-width: 960px) {
    .MuiContainer-maxWidthMd {
      max-width: 800px;
    }
  }
  .houser-request__container {
    margin-bottom: 20px;
    .title {
      margin: 20px 0;
    }
    .modify-service-type {
      max-width: 250px;
    }
    .service-type {
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center;
      justify-content: space-around;
      border: 2px solid;
      border-radius: 4px;
      height: 100%;
      padding: 2px;
    }
    .option-wrapper {
      margin: 20px 0;
      .datetime-container {
        display: flex;
        justify-content: initial;
        .datetime-half-picker {
          max-width: 48%;
          margin-top: 0;
        }
      }
      .option-grid {
        // display: flex;
        // justify-content: center;
        .selection-paper {
          height: 64px;
          justify-content: center;
          align-items: center;
          text-align: center;
          display: flex;
          cursor: pointer;
        }
      }
    }
    .summary-wrapper {
      margin-bottom: 20px;
      .summary-h {
        margin-bottom: 10px;
      }
      .summary-header-wrapper {
        flex-grow: 1;
        line-height: 25px;
        .summary-title-row {
          font-weight: bold;
          font-size: 16px;
          justify-content: space-between;
          display: flex;
        }
        .summary-title-desc {
          justify-content: space-between;
          display: flex;
        }
      }
      .summary-detail-wrapper {
        flex-grow: 1;
        line-height: 25px;
        padding-right: 35px;
        .detail-row {
          font-weight: bold;
          font-size: 14px;
          justify-content: space-between;
          display: flex;
        }
        .detail-desc {
          line-height: 25px;
        }
      }
    }
  }
}