.root {
  position: relative;
  background-color: rgb(244, 246, 248);
  border-radius: 5px;
  padding: 16px 16px 8px;
  overflow: hidden;
}
.header {
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0px 0px 10px;
}
.title {
  flex: 1;
  font-weight: 600;
}
.content {
  & > :not(style) + :not(style) {
    margin: 6px 0px 0px;
  }
}
.item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .label {
    color: var(--textColorLigth);
    margin-right: 5px;
  }
}
.actions {
  display: flex;
  justify-content: flex-end;
  .delete {
    color: rgb(255, 72, 66);
    opacity: 0.7;
    &:hover {
      opacity: 1;
    }
  }
}
