.houser-reservation-table {
  width: 100%;
  overflow-x: auto;
  .res-table {
    min-width: 800px;
    .MuiTableHead-root {
      background-color: #fafafa;
      th {
        font-family: 'Montserrat-Semibold';
      }
    }
    .table-body {
      td {
        font-family: 'Montserrat-Medium';
      }
      &__client-action {
        .MuiIconButton-root {
          color: #3f51b5;
          padding: 0.4rem;
        }
      }
      .user-name-content {
        display: flex;
        align-items: center;
        max-width: 180px;
        &__img {
          margin-right: 0.8rem;
          width: 45px;
          height: 45px;
        }
        &:hover {
          font-weight: 600;
        }
      }
    }
  }
  .status-container {
    display: flex;
    justify-content: center;
  }
}
