.houser-request {
  .MuiOutlinedInput-input {
    padding: 13.5px 14px;
    font-size: 14px;
  }
  .MuiOutlinedInput-multiline {
    padding: 0;
  }
  .MuiOutlinedInput-adornedEnd {
    padding-right: 0;
  }
  @media (min-width: 960px) {
    .MuiContainer-maxWidthMd {
      max-width: 800px;
    }
  }
  .houser-request__container {
    margin-bottom: 20px;
    .title {
      margin: 20px 0;
    }
    .summary-wrapper {
      margin-bottom: 20px;
      .summary-h {
        margin-bottom: 10px;
      }
      .summary-header-wrapper {
        flex-grow: 1;
        line-height: 25px;
        .summary-title-row {
          font-weight: bold;
          font-size: 16px;
          justify-content: space-between;
          display: flex;
        }
        .summary-title-desc {
          justify-content: space-between;
          display: flex;
        }
      }
      .summary-detail-wrapper {
        flex-grow: 1;
        line-height: 25px;
        padding-right: 35px;
        .detail-row {
          font-weight: bold;
          font-size: 14px;
          justify-content: space-between;
          display: flex;
        }
        .detail-desc {
          line-height: 25px;
        }
      }
    }
  }
}
