.dh-petFriendly-detail {
  &__landing-header {
    width: 100%;
    height: 30vh;
    border-bottom: 1.5px solid rgba(0, 0, 0, 0.1);
    background-color: rgba(0,0,0,.08);
    &__cover{
      width: 100%;
      height: 100%;
      position: relative;
      overflow: hidden;
      &--blur {
        width: 100%;
        height: 100%;
        /* Add the blur effect */
        filter: blur(7px);
        -webkit-filter: blur(7px);
        /* Center and scale the image nicely */
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      }
      img {
        position: absolute;
        top: 0;
        left: 50%;
        transform: translate(-50%);
        height: 100%;
        background-size: cover;
        object-fit: cover;
        user-select: none;
        z-index: 2;
      }
    }
    &__not-fount {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      img {
        width: 6rem;
        height: 6rem;
      }
      p {
        padding-top: 0.6rem;
        font-size: 1.3rem;
        font-family: 'Montserrat-Bold';
        text-align: center;
        color: var(--textColorDark2);
      }
    }
  }
  @media (min-width: 768px){
    &__landing-header {
      height: 360px;
    }
  }
  &__content {
    padding-top: 2rem;
    &__info {
      .info-header {
        h2 {
          margin: 0;
          font-size: 2rem;
          font-family: 'Montserrat-Bold';
          color: var(--primaryColor);
        }
        p {
          padding-top: 0.3rem;
          font-family: 'Montserrat-Semibold';
        }
      }
      .info-description {
        padding-top: 1rem;
        h3 {
          margin: 0;
          padding-bottom: .2rem;
          font-size: 1.2rem;
          font-family: 'Montserrat-Semibold';
          color: var(--accentColor);

        }
        p {
          padding-top: 0.5rem;
        }
      }
    }
    &__details{
      h3 {
        margin: 0;
        padding-bottom: 0.5rem;
        font-size: 1.2rem;
        font-family: 'Montserrat-Bold';
        color: var(--accentColor);

      }
      ul {
        list-style: none;
        margin: 0;
        padding: 0;
        li{
          font-size: 0.8rem;
          h4 {
            margin: 0;
            padding: 0.3rem 0;
            color: var(--textColor);
          }
          p {
            padding-bottom: 0.6rem;
          }
        }
      }
    }
  
  }
  &__contact{
    min-height: 200px;
    h3{
      color: var(--accentColor);
      margin: 0;
      padding-bottom: .5rem;
      font-family: 'Montserrat-Bold';
      font-size: 1.2rem;
    }
    h4{
      padding-bottom: .5rem;
    }
    p{
      padding-bottom: .5rem;
    }
    img{
      width: 40px;
    }
  }
  &__section-location {

    &__adress{
      display: flex;
      justify-content: flex-start;
      align-items: baseline;
      text-align: center;

    }
    h3{
      color: var(--accentColor);
      margin: 0 1em 0 0;
      padding-bottom: .5rem;
      font-family: 'Montserrat-Bold';
      font-size: 1.2rem;
    }
    padding: 1em;
    .map-container {
      height: 200px;
      max-height: 50%;
  }
  }
}

