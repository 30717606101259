.title {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 12px;
}
.content {
  background-color: rgb(244, 246, 248);
}
.image {
  // width: 276px;
  // height: 276px;
  margin: 0 auto;
  img {
    display: block;
    width: 100%;
    height: 100%;
    object-fit: contain;
    user-select: none;
  }
  &.loading {
    position: relative;
    img {
      opacity: 0.5;
    }
    .progress {
      position: absolute;
      top: 45%;
      left: 45%;
      width: auto;
      height: auto;
      color: var(--primaryColorDark);
    }
  }
}
.closeBtn {
  position: absolute;
  left: auto;
  right: 10px;
  top: 10px;
  margin-left: 8px;
}
