.dh-upload-image-detail{
	border: 1px solid var(--borderColor);
	border-radius: 5px;
	&__header {
		width: 100%;
		background-color: var(--borderColor);
		span {
			display: inline-block;
			width: .8em;
			height: .8em;
			border-radius: 100%;
			border: .4px solid black;
			margin-left: 5px;
		}
		#switch-red {
			background-color: red;
		}
		#switch-yellow {
			background-color: white;
		}
		#switch-green {
			background-color: green;
		} 
	}
	&__image-container {
		width: 100%;
		height: 150px;
		align-items: center;
		text-align: center;
		img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			cursor: pointer;
			transition: all ease-in-out .2s;
		}
		.profile-img {
			width: 100%;
			height: 100%;
			cursor: pointer;
			text-align: center;
			object-fit: cover;
			border-radius: .5rem;
		}
		.image-progress {
			width: 260px;
			height: 260px;
			position: relative;
			img {
				opacity: 0.5;
			} 
			&__circle {
				position: absolute;
				top: 46%;
				left: 50%;
				margin-left: -24px;
				color: var(--primaryColorDark);
			}
		}
		.drop-zone {
			width: 100%;
			height: 100%;
			margin: 0 auto;
			cursor: pointer;
			border-radius: .5rem;
			border: 2px dashed #ccc;
			background-color: #e8ebed;
			display: table;
			&__content {
				display: table-cell;
				vertical-align: middle;
				svg {
					width: 80px;
					height: 80px;
				}
			}
		}
	}
	&__info-container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-evenly;
		max-width: 400px;
		margin: 0 auto;
		&__description {
			flex: 55%;
			padding: 1rem;
			h4 {
				font-size: .6rem;
				margin: 0;
			}
		}
	}
}
#upload-photo-detail {
	opacity: 0;
	display: none;
	z-index: -1;
}
.dh-upload-image-card{
    width: 300px;
    max-width: 15em;
    margin: 0 auto;
    text-align: center;
    &__container{
			height: 240px;
			overflow: hidden;
			background-color: var(--white);
			border-radius: 4px;
			box-shadow: 0 0 0 1px rgba(63,63,68,0.05), 0 1px 3px 0 rgba(63,63,68,0.15);
			text-align: center;
        .drop-zone {
					width: 100%;
					height: 100%;
					cursor: pointer;
					border-radius: .5rem;
					border: 2px dashed #ccc;
					background-color: #e8ebed;
					display: table;
					&__content {
						display: table-cell;
						vertical-align: middle;
					}
					svg {
						width: 80px;
						height: 80px;
					}
        }
        @media (min-width: 960px) {
            & {
                padding: 1.5rem;
            }
        }
        img{
            cursor: pointer;
            width: 100%;
            height: 100%;
            border-radius: 4px;
            object-fit: cover; 
        }

    }
    &__button{
        text-align: center;
        margin: 2em;
    }
    #upload-photo-card {
        opacity: 0;
        display: none;
        z-index: -1;
    }
    
    .form-container {
        margin-bottom: 3rem;
        #upload-photo {
            opacity: 0;
            display: none;
            z-index: -1;
        }
        .btn-primary {
            width: auto;
            margin: .5rem 0;
        }
    }
    @media only screen and (max-width: 601px) {  
        p {
            margin: 1rem 0;
        }
    }
    
}

