.header {
  padding: 0.5rem 0;
  position: fixed;
  width: 100%;
  z-index: 1000;
  text-align: center;
  background-color: var(--white);
  box-shadow: 0 2px 8px -2px rgba(51, 51, 51, 0.2);
  .wrap {
    display: flex;
    align-items: center;
  }
  .space {
    flex-grow: 1;
  }
  .menu-icon {
    margin-right: 16px;
    // CSS Inutil
    .dh-text-line-icon {
      justify-content: center;
      margin: auto;
      svg {
        fill: #fff;
        display: block;
        width: 33px;
        height: 35px;
      }
    }
  }
  .logo {
    display: block;
    float: right;
    top: 50%;
    font-size: 2em;
    padding: 0;
    text-decoration: none;
    .main-logo {
      display: block;
      width: 160px;
      height: 40px;
    }
  }
  .menu {
    clear: both;
    display: none;
    margin: 0;
    list-style: none;
    padding: 0.5rem 0;
    clear: none;
    float: right;
    max-height: none;
    background-color: transparent;
    li {
      float: left;
      margin: 0 0.5rem;
      text-align: center;
      a {
        padding: 1rem 5px;
        text-decoration: none;
        display: block;
        color: var(--textColorDark);
        font-family: 'Montserrat-Semibold';
        font-size: 15px;
        line-height: 1.4;
        &:hover {
          color: var(--primaryColor);
        }
      }
      .whatsapp-link {
        display: flex;
        align-items: center;
        background: var(--primaryColor);
        padding-top: 6px;
        padding-bottom: 6px;
        padding-left: 12px;
        padding-right: 12px;
        &:hover {
          color: white;
        }
      }
    }
    .dh-user-logged {
      display: flex;
      align-items: center;
      cursor: pointer;
      width: 100%;
      margin: 0 auto;
      &__name {
        margin-right: 8px;
        text-decoration: none;
        padding: 1rem 0;
        &:hover {
          color: var(--primaryColor);
        }
        p {
          font-family: 'Montserrat-Semibold';
          font-size: 15px;
          color: inherit;
        }
        &__loading {
          width: 96px;
          height: 15px;
          margin: 0.18rem;
        }
      }
      &__ico {
        width: 42px;
        height: 42px;
        .profile-image {
          width: 100%;
          height: 100%;
          object-fit: cover;
          text-align: center;
        }
      }
    }
  }
}

/* 48em = 768px */
@media (min-width: 63em) {
  .header {
    padding: 0;
    .menu-icon {
      display: none;
    }
    .logo {
      float: left;
    }
    .menu {
      display: flex;
      align-items: center;
      float: right;
    }
  }
}
