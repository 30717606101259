
.social-content {
  width: 100%;
  .fa-google {
    margin-right: 20px;
  }
  .btn-gogle {
    width: 100%;
    margin-top: 0.7rem;
    margin-bottom: 0.7rem;
    cursor: pointer;
    border: none;
    border-radius: 20px;
    background-color: #df4e41;
    padding: 0.8rem 1rem;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    text-transform: none;
    font-family: 'Montserrat-Semibold';
    letter-spacing: 0.02857em;
    color: var(--white);
    transition: 0.2s ease;
    &:hover,
    &.hover {
      box-shadow: 0px 1px 5px 0px rgba(0,0,0,0.2), 0px 2px 2px 0px rgba(0,0,0,0.14), 0px 3px 1px -2px rgba(0,0,0,0.12);
      transform: scale(1.06, 1.06);
    }
    &:active,
    &.active {
      outline: none;
      box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    }
    &.disabled {
      opacity: 0.4;
      user-select: none;
      pointer-events: none;
    }
  }
}
