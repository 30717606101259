.title {
  display: flex;
  align-items: center;
}
.item {
  display: flex;
  align-items: center;
}
.size {
  display: flex;
  align-items: center;
  gap: 5px;
}
