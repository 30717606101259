.wrap {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.title {
  font-size: var(--fontSize2);
  font-weight: var(--fontWeightSemibold);
  line-height: 1.15;
  letter-spacing: 0.0075em;
}
.actions {
  // flex-shrink: 1;
}
