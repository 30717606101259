.card-user-sumary {
  &__header {
    display: flex;
    justify-content: space-between;
    &__user {
      display: flex;
      align-items: center;
      .header-user-image {
        width: 64px;
        height: 64px;
        min-width: 64px;
        margin-right: 0.5rem;
        img {
          width: 100%;
          height: 100%;
          border-radius: 50%;
          object-fit: cover;
          text-align: center;
          user-select: none;
        }
      }
      .header-user-title {
        h3 {
          margin: 0 0 0.2rem 0;
        }
      }
    }
  }
  .card-body {
    margin-top: 1rem;
    &__stats {
      p {
        color: var(--textColor);
        font-size: 15px;
        font-family: 'Montserrat-Medium';
      }
      h5 {
        font: 500 15px/1.2 'Montserrat-Semibold';
        color: var(--textColorDark);
        margin: 0 0 20px;
      }
      .stats-status {
        display: flex;
        align-items: center;
        p {
          padding-right: 8px;
        }
      }
    }
  }
}