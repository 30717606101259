.step-cuidador-test {
  .card-cuidador-test {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 250px;
    height: 240px;
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-bottom: 1.5rem;
      h3 {
        color: var(--textColorDark2);
        padding-bottom: 0.5rem;
      }
      &--icon {
        width: 96px;
        height: 96px;
        background-color: #f2f2f4;
        padding: 1.2em;
        border-radius: 50%;
      }
      img {
        width: 100%;
        height: 100%;
        user-select: none;
      }
      svg {
        width: 100%;
        height: 100%;
        fill: #000;
      }
    }
  }
  &__exam-link {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    p {
      padding-bottom: 1rem;
    }
    a {
      width: 100%;
    }
    @media only screen and (max-width: 960px) {
      & {
        align-items: center;
      }
    }
  }
}