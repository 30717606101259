.manage-location {
  &__container {
    padding: 1rem 0;
  }
  &__info-location {
    color: var(--primaryColor);
    margin: 0 1rem 0 0;
  }
  &__btn-new-location {
    font-weight: bold;
    transition: 0.2s ease;
    cursor: pointer;
    &:hover {
      color: var(--accentColor);
    }
  }
  &__select-location {
    display: flex;
    align-items: center;
    .select-container {
      display: flex;
      .select-country {
        min-width: 12rem;
        margin-right: 1rem;
      }
    }
    @media only screen and (max-width: 615px) {
      align-items: flex-start;
      .select-container {
        flex-direction: column;
        .select-country {
          min-width: 14rem;
          margin-bottom: 1rem;
        }
      }
    }
  }
}