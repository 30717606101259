.dh-space {
  // display: inline-flex;
  display: flex;
  &.dh-space-vertical {
    flex-direction: column;
  }
  &.dh-space-align-center {
    align-items: center;
  }
  &.dh-space-align-start {
    align-items: flex-start;
  }
  &.dh-space-align-end {
    align-items: flex-end;
  }
  &.dh-space-align-baseline {
    align-items: baseline;
  }
}
