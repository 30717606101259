.message-window-single {
  .container {
    margin: 20px auto;
    .message-wrapper {
      padding: 10px;
      height: 95%;
      min-height: 640px;
      .str-chat-channel-list,
      .str-chat-channel {
        height: 96%;
        margin: auto;
      }
      .str-chat__container {
        flex-direction: column;
      }
    }
  }
}