.item {
  position: relative;
  display: flex;
  height: 100%;
  text-align: center;
  border: 2px solid;
  border-radius: 4px;
  box-sizing: border-box;
  border-color: lightgray;
  cursor: pointer;
  &.active {
    border-color: darkslategrey;
  }
}
.wrap {
  flex: 1 1 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 4px 2px;
}
.label {
  margin-top: 4px;
}
.helper {
  position: absolute;
  bottom: -20px;
  left: 0;
  right: auto;
  max-width: 100%;
  font-size: var(--fontSize7);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.subServices {
  margin-top: 16px;
}
