.root {
  width: 100vw;
  height: 100vh;
  background-color: var(--color-background-alt);
}
.wrap {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.content {
  display: flex;
  align-items: center;
  flex-direction: column;
}
.logo {
  height: 28%;
  width: 28%;
  margin-bottom: 3rem;
}
.title {
  color: var(--textColor) !important;
  font-weight: var(--fontWeightBold) !important;
  margin-bottom: 0.5rem !important;
}
