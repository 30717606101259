.cuidador-reservas {
  padding-top: 1.5rem;
  padding-bottom: 2rem;
  background-color: var(--backgroundColorGray);
  min-height: 52.5vh;
  &__title {
    padding-bottom: 1rem;
  }
  &__toolbar {
    overflow-x: auto;
    padding: 0.5rem 0.3rem;
    margin-left: -0.3rem;
  }
  &__actions {
    display: flex;
    button {
      background-color: var(--white) !important;
      &:nth-last-child(1) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        margin-left: 0.3rem;
      }
      &:nth-last-child(2),
      &:nth-last-child(3) {
        border-radius: 0;
        margin: 0 0.3rem;
      }
      &:nth-last-child(4) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        margin-right: 0.3rem;
      }
      &.active {
        background-color: var(--primaryColorDark) !important;
        color: var(--textColorWhite);
      }
    }
  }
}