.persistent-notification {
  padding: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
  &__image {
    flex: 0 0 auto;
    margin-right: 1rem;
    width: 48px !important;
    height: 48px !important;
    svg {
      width: 50%;
      height: 50%;
      fill: #fff;
    }
    &.email {
      background-color: #f9a64a;
    }
    &.phone {
      background-color: #435cec;
    }
    &.success {
      background-color: #43a047;
    }
    &.error {
      background-color: #e53935;
    }
  }
  &__text {
    flex: 1 1 auto;
    p {
      margin: 0;
      color: var(--textColor);
    }
  }
  &:hover {
    border-radius: 4px;
    background-color: var(--hoverTableAdmin);
  }
}