.step-cuidador-servicios {
	&__section-title {
    margin-top: 1.2rem;
    margin-bottom: 0.5rem;
	}
	&__form-group {
    svg {
      width: 64px;
      height: 64px;
		}
		label {
			width: 80px;
			text-align: center;
			margin: 0 1.5rem;
		}
    @media only screen and (max-width: 601px) {  
      & {
        justify-content: center;
			}
			label {
				margin: 0 1rem;
			}
    }
	}
	&__form-pet-size {
		label {
			max-width: 140px;
			display: flex;
   		justify-content: flex-end;
   		align-items: center;
		}
		.PrivateSwitchBase-root-261 {
			padding: 0;
		}
		.dog-small {
			svg {
				width: 64px;
				height: 64px;
			}
		}
		.dog-medium {
			svg {
				width: 80px;
				height: 80px;
			}
		}
		.dog-big {
			svg {
				width: 96px;
				height: 96px;
			}
		}
	}
	&__flex-outer {
    width: 100%;
    list-style-type: none;
    padding: 0;
    margin: 0;
    max-width: 900px;
    li {
      display: flex;
      flex-direction: column;
      flex-wrap: wrap;
      // align-items: center;
      margin: 1rem 0;
      label {
        padding: 0;
        color: var(--textColor);
        font-family: 'Montserrat-Semibold';
        font-size: 1.17em;
        letter-spacing: .02em;
        // flex: 0 0 200px;
        // max-width: 220px;
      }
      input {
        color: var(--textColor);
        font-family: 'Montserrat-Medium';
        letter-spacing: .08em;
      }
      .MuiFormGroup-row {
        padding-top: 0.5rem;
      }
      .MuiTextField-root {
        width: 70%;
      }
      @media only screen and (max-width: 928px) {  
        .MuiTextField-root {
          width: 100%;
				}
				.MuiFormGroup-row {
					justify-content: center;
				}
      }
    }
  }
}
