.section-gallery {
  padding: 4px 0;
  background-color: var(--white);
  .gallery {
    overflow: hidden;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-template-rows: repeat(2, 16vw);
    grid-gap: 4px;
    &__item {
      margin: 0;
      border-radius: 4px;
      cursor: pointer;
      overflow: hidden;
      &:nth-last-child(3) {
        &.max {
          position: relative;
          .photo-overlay {
            display: block;
            cursor: pointer;
            border-radius: 4px;
            background: rgba(0,0,0, 0.4);
            bottom: 0;
            left: 0;
            position: absolute;
            right: 0;
            top: 0;
            .photo-overlay-content {
              position: relative;
              top: 50%;
              -webkit-transform: translateY(-50%);
              transform: translateY(-50%);
              left: 0;
              position: absolute;
              right: 0;
              strong {
                color: var(--textColorWhite);
                text-shadow: 1px 1px 3px rgba(0,0,0,.6);
              }
              p {
                margin: 0.5rem 0;
                color: var(--textColorWhite);
                text-shadow: 1px 1px 3px rgba(0,0,0,.6);
              }
            }
          }
          &:hover {
            .gallery__img {
              transform: scale(1.05);
              transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
            }
          }
        }
      }
    }
    &__item--0 {
      grid-column-start: 1;
      grid-column-end: 3;
      grid-row-start: 1;
      grid-row-end: 3;
    }
    &__item--1 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    &__item--2 {
      grid-column-start: 3;
      grid-column-end: 4;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    &__item--3 {
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 1;
      grid-row-end: 2;
    }
    &__item--4 {
      grid-column-start: 4;
      grid-column-end: 5;
      grid-row-start: 2;
      grid-row-end: 3;
    }
    &__item--5 {
      grid-column-start: 5;
      grid-column-end: 6;
      grid-row-start: 1;
      grid-row-end: 2;
      display: none;
    }
    &__item--6 {
      grid-column-start: 5;
      grid-column-end: 6;
      grid-row-start: 2;
      grid-row-end: 3;
      display: none;
    }
    &__img {
      width: 100%;
      height: 100%;
      border-radius: 2px;
      object-fit: cover;
      user-select: none;
      transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, opacity 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      &:hover {
        transform: scale(1.05);
        transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
      }
    }
    @media (min-width: 1230px) {
      & {
        grid-template-columns: repeat(5, 1fr);
        grid-template-rows: repeat(2, 12vw);
      }
      &__item {
        &:nth-last-child(1) {
          &.max {
            position: relative;
            .photo-overlay {
              display: block;
              cursor: pointer;
              border-radius: 4px;
              background: rgba(0,0,0, 0.4);
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              .photo-overlay-content {
                position: relative;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                left: 0;
                position: absolute;
                right: 0;
                strong {
                  color: var(--textColorWhite);
                  text-shadow: 1px 1px 3px rgba(0,0,0,.6);
                }
                p {
                  margin: 0.5rem 0;
                  color: var(--textColorWhite);
                  text-shadow: 1px 1px 3px rgba(0,0,0,.6);
                }
              }
            }
            &:hover {
              .gallery__img {
                transform: scale(1.05);
                transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
              }
            }
          }
        }
        &:nth-last-child(3) {
          &.max {
            position: relative;
            .photo-overlay {
              display: none;
            }
          }
        }
      }
      &__item--5,
      &__item--6 {
        display: block;
      }
    }
    @media (max-width: 840px) {
      & {
        grid-template-columns: repeat(1, 1fr);
        grid-template-rows: repeat(1, 48vw);
      }
      &__item {
        &:nth-last-child(1) {
          &.max {
            position: relative;
            .photo-overlay {
              display: block;
              cursor: pointer;
              border-radius: 4px;
              background: rgba(0,0,0, 0.4);
              bottom: 0;
              left: 0;
              position: absolute;
              right: 0;
              top: 0;
              .photo-overlay-content {
                position: relative;
                top: 50%;
                -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
                left: 0;
                position: absolute;
                right: 0;
                strong {
                  color: var(--textColorWhite);
                  text-shadow: 1px 1px 3px rgba(0,0,0,.6);
                }
                p {
                  margin: 0.5rem 0;
                  color: var(--textColorWhite);
                  text-shadow: 1px 1px 3px rgba(0,0,0,.6);
                }
              }
            }
            &:hover {
              .gallery__img {
                transform: scale(1.05);
                transition: -ms-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, -webkit-transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s, transform 450ms cubic-bezier(0.645, 0.045, 0.355, 1) 0s;
              }
            }
          }
        }
      }
      &__item--0 {
        grid-column-start: 1;
        grid-column-end: 2;
        grid-row-start: 1;
        grid-row-end: 2;
      }
      &__item--1,
      &__item--2,
      &__item--3,
      &__item--4,
      &__item--5,
      &__item--6 {
        display: none;
      }
    }
  }
}
