.card-image-setting {
  .dh-avatar {
    height: 8rem;
    width: 8rem;
    min-width: 8rem;
    border-radius: 50%;
    background-color: #e6e6ea;
    margin-right: 2rem;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 50%;
    }
  }
  .image-actions {
    p {
      margin-top: 0.5rem;
    }
  }
  @media only screen and (max-width: 615px) { 
    .dh-avatar {
      margin-right: 0;
      margin-bottom: 1rem;
    }
    .image-actions {
      text-align: center;
    }
  }
}

.profile-edit {
  &__header {
    padding: 1rem 1.5rem;
    border-bottom: 1px solid rgba(0,0,0,.1)!important;
    display: flex;
    justify-content: center;
  }
  &__body {
    display: flex;
    justify-content: center;
    padding: 1rem 1.5rem !important;
  }
  &__presentation {
    height: 15rem;
    width: 15rem;
    min-width: 9.5rem;
    border-radius: 50%;
    background-color: #e6e6ea;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 50%;
      object-fit: cover;
    }
    &.image-progress {
      img {
        opacity: 0.5;
      }
      .progress-circle {
        position: absolute;
        left: 43%;
        top: 43%;
        color: var(--primaryColorDark);
      }
    }
  }
  &__actions {
    background-color: #e6e6ea;
  }
}