.dh-card-user-info {
  .info-form {
    width: 100%;
    padding-top: 0.5rem;
    .flex-outer {
      list-style-type: none;
      padding: 0;
      margin: 0;
      background: var(--white);
      li {
        display: flex;
        flex-wrap: wrap;
        margin: 0 0 2rem;
        &.last-row {
          margin: 0;
        }
        label {
          width: 180px;
          padding: 0 8px;
          color: var(--textColor);
          font-family: 'Montserrat-Semibold';
          font-size: 15px;
          letter-spacing: .09em;
        }
        input {
          padding-bottom: .6rem;
          padding-top: .6rem;
          color: var(--textColor);
          font-family: 'Montserrat-Medium';
          font-size: 14px;
          letter-spacing: .08em;
          &.Mui-disabled {
            background-color: rgba(0, 0, 0, 0.12);
          }
        }
        .MuiInputBase-multiline {
          padding-bottom: .6rem;
          padding-top: .6rem;
        }
        textarea {
          color: var(--textColor);
          font-family: 'Montserrat-Medium';
          font-size: 14px;
          letter-spacing: .08em;
        }
        .MuiTextField-root {
          width: 73%;
        }
        @media only screen and (max-width: 928px) { 
          label {
            padding: 8px 0;
          }
          .MuiTextField-root {
            width: 100%;
          }
        }
        button {
          margin-left: auto;
        }
      }
    }
  }
  @media only screen and (max-width: 601px) { 
    .info-form {
      width: 100%;
      .flex-outer {
        li {
          label {
            padding: 8px 8px 8px 0;
          }
        }
      }
    }
  }
}