.admin-res-detail {
  &__section-title {
    margin-bottom: 1rem;
    h3 {
      color: var(--textColorDark2);
      padding-bottom: 2px;
    }
    .border-space {
      width: 80px;
      height: 4px;
      background-color: var(--primaryColor);
    }
  }
  .admin-res-detail {
    &__body {
      &--item {
        margin-bottom: 0.8rem;
        p {
          color: var(--textColorLigth);
          padding-bottom: 2px;
        }
        h4 {
          color: var(--textColorDark2);
        }
      }
      &--item-price {
        display: flex;
        align-items: center;
        padding-top: 1rem;
        div {
          display: flex;
          align-items: center;
          &:not(:first-child) {
            margin-left: 2rem;
          }
          p {
            color: var(--textColorLigth);
          }
          h3 {
            color: var(--textColorDark2);
            font-family: 'Montserrat-Bold';
            font-size: 1.3rem;
            padding-left: 1rem;
          }
        }
      }
      @media (min-width: 960px) {
        &--grid-1 {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 16px;
        }
      }
    }
  }
  .info-detail {
    &__title-section {
      padding-top: 1rem;
      padding-bottom: 0.5rem;
    }
    &__body {
      &--item {
        margin-bottom: 0.8rem;
        p {
          color: var(--textColorLigth);
          padding-bottom: 2px;
        }
        h4 {
          color: var(--textColorDark2);
        }
      }
      @media (min-width: 960px) {
        &--grid-1 {
          display: grid;
          grid-template-columns: repeat(4, 1fr);
          grid-gap: 16px;
        }
        &--grid-2 {
          display: grid;
          grid-template-columns: repeat(5, 1fr);
          grid-gap: 16px;
        }
      }
    }
    &__grid-pets {
      &__toolbar {
        overflow-x: auto;
        justify-content: space-between;
      }
      &__body {
        display: grid;
        grid-template-columns: repeat(4, 1fr);
        grid-gap: 16px;
        padding: 0.5rem;
        &--item {
          min-width: 180px;
          overflow: hidden;
          display: flex;
          align-items: center;
          background-color: #f2f2f4;
          border-top-left-radius: 36px;
          border-bottom-left-radius: 36px;
          border-top-right-radius: 8px;
          border-bottom-right-radius: 8px;
          border: 1px solid rgba(0, 0, 0, 0.1);
          transition: 0.2s ease;
          padding: 0 0;
          .MuiAvatar-root {
            width: 72px;
            height: 72px;
            color: var(--colorPrimary);
            margin-right: 0.8rem;
            background-color: #d4d4d4;
            transition: 0.2s ease;
          }
          &:hover {
            transform: scale(1.06, 1.06);
          }
        }
      }
    }
  }
  .stay-detail {
    &__flex {
      display: flex;
    }
    &__image {
      width: 40%;
      height: 250px;
      img {
        width: 100%;
        height: 100%;
        user-select: none;
        object-fit: cover;
        border-radius: 8px;
      }
    }
    &__content {
      width: 60%;
      padding-left: 1rem;
      &--item {
        margin-bottom: 0.8rem;
        p {
          color: var(--textColorLigth);
        }
        h4 {
          color: var(--textColorDark2);
        }
      }
      @media (min-width: 960px) {
        &--grid {
          display: grid;
          grid-template-columns: repeat(3, 1fr);
          grid-gap: 16px;
        }
      }
    }
    @media (max-width: 960px) {
      &__flex {
        flex-direction: column;
      }
      &__image {
        width: 100%;
      }
      &__content {
        width: 100%;
        padding-top: 1rem;
        padding-left: 0;
      }
    }
  }
  .actions-detail {
    display: flex;
    &__container {
      padding: 5px;
      h4 {
        padding-bottom: 0.5rem;
      }
    }
    &__btn-action {
      button {
        width: 240px;
        height: 68px;
        justify-content: center;
        align-items: center;
        svg {
          margin-left: 0.8rem;
          //font-size: 1.5rem;
        }
      }
    }
  }
}