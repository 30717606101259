.item {
  position: relative;
  height: 228px;
  .image {
    width: 100%;
    height: 100%;
    border-radius: 4px;
    object-fit: cover;
  }
}
.deleteBtn {
  cursor: pointer;
  margin: 0.5rem;
  position: absolute;
  right: 0;
  opacity: 0.4;
  transition: 0.2s ease;
  svg {
    color: rgb(230, 55, 87);
    background-color: rgba(0, 0, 0, 0.8);
    border-radius: 8px;
    padding: 0.5rem;
    width: 24px;
    height: 24px;
  }
  &:hover {
    opacity: 1;
  }
}
