.pet-detail-container {
  .dh-card-pet-extra {
    &__detail {
      padding-bottom: 0.8rem;
      // border-bottom: 1px solid rgba(0, 0, 0, 0.1);
      .detail-title {
        width: 28%;
        padding-right: 0.5rem;
      }
      p {
        width: 100%;
      }
      @media only screen and (max-width: 615px) {
        .detail-title {
          width: 50%;
          padding-bottom: 0.3rem;
        }
      }
    }
  }
}
