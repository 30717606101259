.float {
  position: fixed;
  width: 112px;
  height: 112px;
  bottom: 48px;
  right: 48px;
  background-color: #45d9cb;
  border-radius: 50%;
  text-align: center;
  // box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.4), 0 1px 3px 0 rgba(63, 63, 68, 0.5);
  box-shadow: 0 2px 6px rgba(51, 51, 51, 0.4);
  z-index: 1;
  transition: 0.4s ease;
  user-select: none;
  img {
    width: 100%;
    height: 100%;
    user-select: none;
  }
  &:hover {
    transform: scale(1.05, 1.05);
    box-shadow: 0 3px 15px rgba(51, 51, 51, 0.5);
  }
  @media (max-width: 960px) {
    width: 96px;
    height: 96px;
    bottom: 24px;
    right: 24px;
  }
}
