.dh-badge-status-simple {
  font-family: 'Montserrat-Semibold';
  font-size: 14px;
  white-space: nowrap;
  &.verificado {
    color: rgb(67, 160, 71);
    text-shadow: 0 0 0.8px rgb(67, 160, 71);
  }
  &.no-verificado {
    color: rgb(238, 168, 0);
    text-shadow: 0 0 0.8px rgb(238, 168, 0);
  }
  &.error {
    color: rgb(229, 57, 53);
    text-shadow: 0 0 0.8px rgb(229, 57, 53);
  }
}
