.card {
  box-shadow: var(--shadowElevation1);
  border-radius: var(--borderRadiusMedium);
  overflow: hidden;
}
.top {
  background-color: var(--primaryColor);
}
.title {
  color: var(--textColorWhite);
  padding-top: 32px;
  padding-bottom: 32px;
  @media (min-width: 960px) {
    padding-top: 48px;
    padding-bottom: 48px;
    h1 {
      font-size: var(--fontSize0);
    }
  }
}
.svg {
  width: 100%;
  margin-bottom: -8px;
}
.container {
  padding-bottom: 64px;
}
.name {
  margin-bottom: 0.55em;
}
.box {
  p {
    color: var(--textColorAlt2);
    font-size: var(--fontSize5);
    line-height: 1.5;
  }
  a {
    color: var(--primaryColor);
    &:hover {
      text-decoration: underline;
    }
  }
}
