.root {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #e8ebed;
  border: 2px dashed #bdbdbd;
  border-radius: var(--borderRadiusMedium);
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  cursor: pointer;
  svg {
    width: 40px;
    height: 40px;
  }
}
