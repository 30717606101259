.test-section {
  .card-cuidador-test {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    &__icon {
      width: 74px;
      height: 74px;
      background-color: #f2f2f4;
      padding: 1.2em;
      border-radius: 50%;
      img {
        width: 100%;
        height: 100%;
        user-select: none;
      }
      svg {
        width: 100%;
        height: 100%;
        fill: #000;
      }
    }
    &__content {
      display: flex;
      flex-direction: column;
      align-items: center;
      h3 {
        color: var(--textColorDark2);
        padding-bottom: 0.5rem;
        text-align: center;
      }
    }
  }
  &__exam-link {
    display: flex;
    flex-direction: column;
    margin-top: 1.5rem;
    p {
      padding-bottom: 1rem;
    }
    &--end {
      display: flex;
      flex-wrap: wrap;
      align-items: center;
    }
    &--text {
      padding-left: 1rem;
      &.valid {
        color: rgb(133, 200, 67);
        text-shadow: 2px 2px 4px rgba(133, 200, 67, 0.1);
      }
      &.no-valid {
        color: rgb(241, 71, 38);
        text-shadow: 2px 2px 4px rgba(241, 71, 38, 0.1);
      }
    }
    @media only screen and (max-width: 960px) {
      & {
        align-items: center;
        &--end {
          justify-content: center;
        }
        &--text {
          padding-top: 0.8rem;
        }
      }
    }
  }
}