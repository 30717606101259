/*
* 0. Font - Montserrat
*/

@font-face {
  font-family: 'Montserrat-Regular'; /*a name to be used later*/
  src: url('../assets/font/montserrat_regular.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Montserrat-Medium'; /*a name to be used later*/
  src: url('../assets/font/montserrat_medium.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Montserrat-Italic'; /*a name to be used later*/
  src: url('../assets/font/montserrat_italic.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Montserrat-Semibold'; /*a name to be used later*/
  src: url('../assets/font/montserrat_semibold.ttf'); /*URL to font*/
}

@font-face {
  font-family: 'Montserrat-Bold'; /*a name to be used later*/
  src: url('../assets/font/montserrat_bold.ttf'); /*URL to font*/
}

/*
* 1. Reduce browser inconsistencies
* Source: https://gist.github.com/roque363/3742ac45d42e5e48e7af091561d95d49
*/

@import './reset.scss';

/*
* 2. Variables
*/

@import './vars.scss';

/*
* 3. Common styles
*/

@import './global.scss';

/*
* 5. App styles
*/

@import './projectStyles.scss';

/*
* 6. Third party
*/

@import '../assets/scss/marker.scss';

@import '../assets/scss/text-field.scss';

@import '../assets/scss/menu-icon.scss';
