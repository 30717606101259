.swal2-container {
  z-index: 1250 !important;
}
h1 {
  &.marginx0 {
    margin: 0;
  }
  &.marginx05 {
    margin: 0.5rem 0;
  }
  &.marginx1 {
    margin: 1rem 0;
  }
  &.medium {
    font-size: 1.5rem;
  }
  &.small {
    font-size: 1rem;
  }
  &.dh-title-section {
    font-size: 2.2rem;
    line-height: 1.2;
  }
  &.dh-title {
    text-align: center;
    font-size: 2.2rem;
    font-weight: 700;
    line-height: 1.17;
    letter-spacing: 0.00735em;
    margin: 1rem 0;
  }
}

h2 {
  &.text-primary {
    font-size: 1.5em;
    line-height: 1.4;
  }
  &.text-bold {
    font-family: 'Montserrat-Medium';
    font-weight: bold;
    color: #444444;
    line-height: 1.4;
  }
  &.text-big {
    font-family: 'Montserrat-Bold';
    font-size: 2rem;
    color: #444444;
    line-height: 1.4;
  }
  &.marginx05 {
    margin: 0.5rem 0;
  }
  &.marginx08 {
    margin: 0.8rem 0;
  }
  &.dh-title-section {
    font-family: 'Montserrat-Bold';
    font-size: 2rem;
    margin-bottom: 1.2rem;
    text-align: center;
  }
  &.dh-title-sub-section {
    font-family: 'Montserrat-Semibold';
    font-weight: 600;
    font-size: 2rem;
    margin-bottom: 0.6rem;
    text-align: center;
  }
  &.admin-title {
    font-family: 'Montserrat-Medium';
    font-weight: bold;
    font-size: 1.5rem;
    line-height: 1.15;
    letter-spacing: 0.0075em;
    margin: 0;
  }
}

h3 {
  color: var(--textColor);
  &.marginx0 {
    margin: 0;
  }
  &.marginx05 {
    margin: 0.5rem 0;
  }
  &.dh-text-section {
    font-size: 1rem;
  }
  &.margin-bottom {
    margin-bottom: 1rem;
  }
}

h4 {
  color: var(--textColor);
  &.text-primary {
    font-size: 20px;
    line-height: 1.4;
  }
  &.marginx05 {
    margin: 0.5rem 0;
  }
  &.section-title {
    font-size: 20px;
  }
  &.sub-title {
    margin: 0.5rem 0;
    font-size: 16px;
  }
  &.sub-section {
    margin-top: 2.5rem;
    font-size: 1.1rem;
  }
}

p {
  font-size: 0.9rem;
  line-height: 1.4;
  margin: 0;
  color: var(--textColor);
  &.marginx05 {
    margin: 0.5rem 0;
  }
  &.margin-top-x1 {
    margin-top: 1rem;
  }
  &.justify {
    text-align: justify;
  }
  &.center {
    text-align: center;
  }
  &.text-primary {
    margin: 0 0 0.5rem 0;
    font-size: 14px;
  }
  &.text-header {
    font-family: 'Montserrat-Semibold';
    font-size: 15px;
    padding: 1rem 0;
  }
  &.dh-text-bold {
    font-family: 'Montserrat-Bold';
  }
  &.dh-text-semibold {
    font-family: 'Montserrat-Semibold';
  }
  &.small {
    font-size: 0.8rem;
    color: var(--textColorLigth);
  }
  &.regular {
    font-size: 14px;
    color: var(--textColor);
    line-height: 1.6;
  }
  &.medium {
    font-size: 1rem;
  }
}

span {
  &.dh-title-section {
    color: var(--textColor);
    font-family: 'Montserrat-Semibold';
    font-size: 19px;
    line-height: 20px;
    letter-spacing: 0.1px;
  }
}

/* ------ Only Text ------- */

.dh-title {
  color: var(--textColor);
  text-align: center;
  font-size: 2.2rem;
  font-weight: var(--fontWeightBold);
  line-height: 1.17;
  letter-spacing: 0.00735em;
  margin-bottom: 20px;
}

/* ------ Button Styles ------- */

.btn-primary {
  outline: none;
  text-align: center;
  // display: inline-block;
  display: inline-flex;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Semibold';
  padding: 10px 1rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  border-radius: var(--borderRadius);
  background: var(--primaryColor);
  color: var(--backgroundColor);
  transition: 0.2s ease;
  &:hover,
  &.hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1.07, 1.07);
  }
  &.no-hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1, 1);
  }
  &:active,
  &.active {
    background: var(--primaryColorDark);
    outline: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  &.disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.border {
    background: transparent;
    border: 1.5px solid var(--primaryColorDark);
    color: var(--primaryColorDark);
  }
  &.medium {
    padding: 14px 24px;
  }
  &.small {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
  }
  &.icon {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }
  &.icon-left {
    svg {
      font-size: 20px;
      margin-left: 0.3rem;
      margin-right: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
  svg {
    font-size: 20px;
    margin-right: 0.3rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-accent {
  outline: none;
  text-align: center;
  // display: inline-block;
  display: inline-flex;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Semibold';
  padding: 10px 1rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  border-radius: var(--borderRadius);
  background: var(--accentColor);
  color: var(--textColorWhite);
  transition: 0.2s ease;
  &:hover,
  &.hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1.07, 1.07);
  }
  &.no-hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1, 1);
  }
  &:active,
  &.active {
    background: var(--accentColor);
    outline: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  &.disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.border {
    background: transparent;
    border: 1.5px solid var(--accentColor);
    color: var(--accentColor);
  }
  &.medium {
    padding: 14px 24px;
  }
  &.small {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
  }
  &.icon {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }
  svg {
    font-size: 20px;
    margin-right: 0.3rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-primary-outline {
  text-align: center;
  // display: inline-block;
  display: inline-flex;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Semibold';
  padding: 10px 1rem;
  cursor: pointer;
  white-space: nowrap;
  border-radius: var(--borderRadius);
  border: 2px solid var(--primaryColor);
  color: var(--textColor);
  transition: 0.2s ease;
  &:hover,
  &.hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1.07, 1.07);
  }
  &.no-hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1, 1);
  }
  &:active,
  &.active {
    background: var(--primaryColorDark);
    outline: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  &.disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.border {
    background: transparent;
    border: 1.5px solid var(--primaryColorDark);
    color: var(--primaryColorDark);
  }
  &.medium {
    padding: 14px 24px;
  }
  &.small {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
  }
  &.icon {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }
  svg {
    font-size: 20px;
    margin-right: 0.3rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-toggle-list-right {
  text-align: center;
  background-color: var(--primaryColor);
  border-right: 1px solid var(--primaryColor);
  display: inline-block;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Semibold';
  padding: 10px 1rem;
  cursor: pointer;
  white-space: nowrap;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  border: 2px solid var(--primaryColor);
  color: var(--white);
  transition: 0.2s ease;
  &:hover,
  &.hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1.07, 1.07);
  }
  &.no-hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1, 1);
  }
  &:active,
  &.active {
    background: var(--primaryColorDark);
    outline: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    background: var(--primaryColorDark);
    outline: none;
    border: 4px solid var(--borderColor);
  }
  &.disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.border {
    background: transparent;
    border: 1.5px solid var(--primaryColorDark);
    color: var(--primaryColorDark);
  }
  &.medium {
    padding: 14px 24px;
  }
  &.small {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
  }
  &.icon {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }
  svg {
    font-size: 20px;
    margin-right: 0.3rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-toggle-list-left {
  text-align: center;
  background-color: var(--primaryColor);
  border-right: 1px solid var(--primaryColor);
  display: inline-block;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Semibold';
  padding: 10px 1rem;
  cursor: pointer;
  white-space: nowrap;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border: 2px solid var(--primaryColor);
  color: var(--white);
  transition: 0.2s ease;
  &:hover,
  &.hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1.07, 1.07);
  }
  &.no-hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1, 1);
  }
  &:active,
  &.active {
    background: var(--primaryColorDark);
    outline: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  &:focus {
    background: var(--primaryColorDark);
    outline: none;
    border: 4px solid var(--borderColor);
  }
  &.disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.border {
    background: transparent;
    border: 1.5px solid var(--primaryColorDark);
    color: var(--primaryColorDark);
  }
  &.medium {
    padding: 14px 24px;
  }
  &.small {
    padding: 10px 12px;
    font-size: 14px;
    font-weight: 500;
  }
  &.icon {
    svg {
      width: 0.8em;
      height: 0.8em;
    }
  }
  svg {
    font-size: 20px;
    margin-right: 0.3rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-default {
  outline: none;
  text-align: center;
  // display: inline-block;
  display: inline-flex;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Semibold';
  padding: 8px 24px;
  cursor: pointer;
  user-select: none;
  border-radius: var(--btnBorderRadius);
  background: var(--white);
  color: var(--textColorDark2);
  transition: 0.2s ease;
  box-shadow: var(--boxShadow);
  &:hover,
  &.hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.4);
    transform: scale(1.06, 1.06);
  }
  &.no-hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0);
    transform: scale(1, 1);
    background: rgb(240, 240, 240);
  }
  &:active,
  &.active {
    background: var(--btnHoverColor);
    outline: none;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
  }
  &.right {
    margin-right: 16px;
  }
  &.disabled {
    opacity: 0.4;
    user-select: none;
    pointer-events: none;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.medium {
    padding: 14px 24px;
    width: auto;
  }
  &.small {
    padding: 10px 16px;
    width: auto;
    font-size: 14px;
    font-weight: 500;
  }
  &.icon-left {
    svg {
      margin-left: 0.3rem;
      margin-right: 0;
      display: inline-block;
      vertical-align: middle;
    }
  }
  svg {
    margin-right: 0.3rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-success {
  outline: none;
  text-align: center;
  align-items: center;
  display: inline-flex;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Medium', sans-serif;
  padding: 10px 1rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  border-radius: 8px;
  background: #43a047;
  color: var(--textColorWhite);
  transition: 0.2s ease;
  &:hover,
  &.hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1.03, 1.03);
  }
  &.no-hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1, 1);
  }
  &.disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.border {
    background: transparent;
    border: 1.5px solid #43a047;
    color: #43a047;
  }
  svg {
    margin-right: 0.2rem;
    display: inline-block;
    vertical-align: middle;
  }
}

.btn-danger {
  outline: none;
  text-align: center;
  align-items: center;
  display: inline-flex;
  border: none;
  font-size: 15px;
  font-family: 'Montserrat-Medium', sans-serif;
  padding: 10px 1rem;
  cursor: pointer;
  user-select: none;
  white-space: nowrap;
  border-radius: 8px;
  background: #e53935;
  color: var(--textColorWhite);
  transition: 0.2s ease;
  &:hover,
  &.hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1.03, 1.03);
  }
  &.no-hover:hover {
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15);
    transform: scale(1, 1);
  }
  &.disabled {
    opacity: 0.3;
    user-select: none;
    pointer-events: none;
  }
  &.btn-block {
    display: block;
    width: 100%;
  }
  &.border {
    background: transparent;
    border: 1.5px solid #e53935;
    color: #e53935;
  }
  svg {
    margin-right: 0.2rem;
    display: inline-block;
    vertical-align: middle;
  }
}

/* ------- Toolkit de DogHouser ------- */

.layout-space {
  padding-top: 4.313rem;
  @media only screen and (max-width: 1124px) {
    & {
      padding-top: 4rem;
    }
  }
}

.dh-columns {
  display: flex;
  &.content-space {
    justify-content: space-between;
  }
  &.items-center {
    align-items: center;
  }
}

.dh-flex {
  display: flex;
  &.content-space {
    justify-content: space-between;
  }
  &.content-end {
    justify-content: flex-end;
  }
  &.content-center {
    justify-content: center;
  }
  &.items-center {
    align-items: center;
  }
  &.items-end {
    align-items: flex-end;
  }
  &.column {
    flex-direction: column;
  }
}

.dh-flex-responsive {
  display: flex;
  &.content-space {
    justify-content: space-between;
  }
  &.items-center {
    align-items: center;
  }
  @media only screen and (max-width: 615px) {
    & {
      flex-direction: column;
    }
  }
}

.dh-justify-content-center {
  justify-content: center;
}

.dh-card-elevation {
  overflow: hidden;
  background-color: var(--white);
  border-radius: 4px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  padding: 1rem;
  // @media (min-width: 960px) {
  //   & {
  //     padding: 1.5rem;
  //   }
  // }
}

.dh-card-border {
  overflow: hidden;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
  padding: 1rem;
  .dh-button-container {
    flex-wrap: wrap;
    @media (max-width: 640px) {
      div:not(:first-child) {
        padding-left: 0 !important;
      }
    }
  }
  @media (min-width: 960px) {
    & {
      padding: 1.5rem;
    }
  }
}

.dh-simple-elevation {
  overflow: hidden;
  border-radius: 4px;
  background-color: var(--white);
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
}

.dh-simple-border {
  overflow: hidden;
  background-color: var(--white);
  border-radius: 4px;
  border: 1px solid rgba(0, 0, 0, 0.1);
}

.margin-right {
  &-x1 {
    margin-right: 1rem;
  }
  &-x2 {
    margin-right: 2rem;
  }
  &-x3 {
    margin-right: 3rem;
  }
}
.margin-left {
  &-x1 {
    margin-left: 1rem;
  }
  &-x2 {
    margin-left: 2rem;
  }
  &-x3 {
    margin-left: 3rem;
  }
}
.margin-top {
  &-x0 {
    margin-top: 0rem;
  }
  &-x1 {
    margin-top: 1rem;
  }
  &-x2 {
    margin-top: 2rem;
  }
  &-x3 {
    margin-top: 3rem;
  }
}
.margin-bottom {
  &-x1 {
    margin-bottom: 1rem;
  }
  &-x15 {
    margin-bottom: 1.5rem;
  }
  &-x2 {
    margin-bottom: 2rem;
  }
  &-x3 {
    margin-bottom: 3rem;
  }
}

.dh-router,
.dh-no-decoration {
  color: inherit;
  text-decoration: none;
}

.dh-link {
  cursor: pointer;
}

/* ------- Utils ------- */

.admin-main__container {
  .dashboard-cuidador {
    background-color: var(--white);
    border-radius: 4px;
    box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  }
}

.truncate {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.text-center {
  text-align: center;
}

.hide {
  display: none !important;
}

.login-link {
  color: var(--textColor);
  cursor: pointer;
}

.join-logo {
  width: 300px;
  height: 80px;
  margin-top: 16px;
  margin-left: 16px;
  @media only screen and (max-width: 601px) {
    & {
      display: none;
    }
  }
}

.divider-line {
  border-bottom-width: 1px !important;
  border-bottom-color: var(--color-border-base) !important;
  border-bottom-style: solid !important;
  margin: 0;
}

.divider {
  width: 100%;
  text-align: center;
  border-bottom: 1px solid #5a5655;
  line-height: 0.1em;
  margin: 1rem 0;
  span {
    background: #fff;
    padding: 0 2rem;
  }
}

.dh-space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.list-unstyled {
  padding-left: 0;
  list-style: none;
  margin-left: 0;
}

.slide-image {
  height: 100px;
  width: 250px;
  margin: 0 1rem;
}

.item-loading {
  text-align: center;
  &__circle {
    /* IMPORTANTE */
    display: inline-block !important;
  }
  &__text {
    margin: 1rem;
    display: inline-block !important;
  }
}

/* ------- Material-UI ------- */

.MuiFormLabel-root.Mui-focused {
  color: #5a5655 !important;
}

.MuiOutlinedInput-root .Mui-focused,
.MuiOutlinedInput-notchedOutline {
  border-color: #5a5655 !important;
}

.MuiRadio-colorSecondary.Mui-checked {
  color: var(--accentColor) !important;
  &:hover {
    background-color: rgba(249, 166, 74, 0.08) !important;
  }
}

.MuiIconButton-colorSecondary:hover {
  background-color: rgba(249, 166, 74, 0.08) !important;
}

.MuiSwitch-colorSecondary.Mui-checked {
  color: var(--primaryColor) !important;
  &:hover {
    background-color: rgba(90, 148, 142, 0.08) !important;
  }
}

.MuiIconButton-colorPrimary:hover {
  background-color: rgba(249, 166, 74, 0.08) !important;
}

.MuiCheckbox-colorPrimary.Mui-checked {
  color: var(--accentColor) !important;
}

.MuiSwitch-colorSecondary.Mui-checked + .MuiSwitch-track {
  background-color: var(--primaryColor) !important;
}

.MuiLinearProgress-colorPrimary {
  background-color: #d4d4d2 !important;
}

.MuiLinearProgress-barColorPrimary {
  background-color: var(--primaryColor) !important;
}

.MuiPickersToolbar-toolbar {
  background-color: var(--primaryColor) !important;
}

/* ------- Re-Captcha ------- */
.grecaptcha-badge {
  display: none;
}

.dh-filter-selection {
  display: flex;
  // flex-wrap: wrap;
  align-items: center;
  background-color: var(--backgroundFilterAdmin);
  border-radius: 8px;
  box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
  overflow-x: auto;
  &__item {
    height: 48px;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    &.item-button {
      user-select: none;
      cursor: pointer;
      svg {
        margin-left: 4px;
      }
    }
  }
  &__style {
    display: flex;
    align-items: center;
    height: 100%;
    padding: 0 1rem;
  }
  &__popover-filter {
    .MuiPaper-root {
      margin-top: 0.5rem;
      border-radius: 8px;
      min-width: 272px;
      padding: 1rem;
      box-shadow: 0 0 0 1px rgba(63, 63, 68, 0.05), 0 1px 3px 0 rgba(63, 63, 68, 0.15);
    }
    &--title {
      h3 {
        font-family: 'Montserrat-Medium';
        font-size: 1.1rem;
        margin: 0;
      }
    }
    &--formcontrol {
      padding: 0.5rem 0 !important;
      // width: 100%;
      .MuiFormControlLabel-root {
        margin-right: 0;
        .MuiTypography-body1 {
          font-family: 'Montserrat-Medium';
          color: var(--textColor);
          user-select: none;
        }
      }
    }
    &--actions {
      display: flex;
      justify-content: center;
      padding-top: 1rem;
      border-top: 1px solid rgb(235, 235, 235);
    }
  }
}
