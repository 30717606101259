.new-pet-photo-container {
  margin-top: 1.5rem;
  .photo-container {
    margin: 0 auto;
    margin-bottom: 1.5rem;
    width: 260px;
    height: 260px;
    background-color: #e8ebed;
    cursor: pointer;
    border-radius: .5rem;
    border: 2px dashed #bdbdbd;
    .photo-label {
      cursor: pointer;
      width: 260px;
      height: 260px;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      img {
        width: 100%;
        height: 100%;
        border-radius: .5rem;
        object-fit: cover;
      }
      svg {
        fill: #a0a0a0;
        width: 40px;
        height: 40px;
      }
      .edit-icon {
        position: absolute;
        fill: var(--primaryColor);
        bottom: 10px;
        right: 10px;
      }
    }
    .image-progress {
      position: relative;
      img {
        opacity: 0.5;
      }
      &__circle {
        position: absolute;
        top: 45%;
        left: 50%;
        margin-left: -24px;
        color: var(--primaryColorDark);
      }
    }
  }
}