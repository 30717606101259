.dh-main-delivery-image {
  width: 100%;
  height: 90vh;
  overflow: hidden;
  display: flex;
  align-items: center;
  background: 
  linear-gradient( rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5) ),
  url('../../assets/images/delivery-houser.jpg') no-repeat center center;
  background-size: cover;

  &__container {
    width: 50% !important;
  }
  h1 {
    color: var(--textColorWhite);
    font-family: 'Montserrat-Semibold';
    font-size: 34px;
    letter-spacing: 1px;
    margin-top: 0;
  }
  h3 {
    color: var(--textColorWhite);
    font-family: 'Montserrat-Italic';
    font-size: 18px;
    letter-spacing: 1px;
  }
  @media (max-width: 768px) {
    & {
      height: 75vh;
    }
    &__container {
      width: 100% !important;
      text-align: center;
      align-items: center;
    }
  }
}